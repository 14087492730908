import { useQuery } from "urql";

import {
  CollectionDocumentFilter,
  CollectionDocumentOrderBy,
  GetCollectionDocumentsDocument,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api";
import { ApiHookProps } from "../types.ts";

export const useCollectionDocuments = ({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<CollectionDocumentFilter, CollectionDocumentOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({
    query: GetCollectionDocumentsDocument,
    variables,
    pause,
  });
};
