import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ContractType } from "../../../../../../graphql/generated.ts";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";
import { useStyles } from "../../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
  type: ContractType;
}

export const useCRCreditLimitDebitBalanceCardsInfo = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  const { classes } = useStyles();

  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: "Initial Credit Limit",
          content: data?.initialCreditLimit && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.initialCreditLimit)}
            </Text>
          ),
        },
        {
          label: "Credit Limit",
          content: data?.creditLimit && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.creditLimit)}
            </Text>
          ),
        },
        {
          label: "Current Month Realization",
          content: data?.currentMonthRealization && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.currentMonthRealization)}
            </Text>
          ),
        },
        {
          label: "Debit Balance",
          content: data?.debitBalance && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.debitBalance)}
            </Text>
          ),
        },
        {
          label: "Value In Currency Origin",
          content: data?.valueInOriginalCurrency && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.valueInOriginalCurrency)}
            </Text>
          ),
        },
      ];
    }

    if (type === ContractType.Bond) {
      return [
        {
          label: "Debit Balance",
          content: data?.debitBalance && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.debitBalance)}
            </Text>
          ),
        },
        {
          label: "Value In Currency Origin",
          content: data?.valueInOriginalCurrency && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.valueInOriginalCurrency)}
            </Text>
          ),
        },
        {
          label: "Market Value",
          content: data?.marketValue && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.marketValue)}
            </Text>
          ),
        },
        {
          label: "Acquisition Value",
          content: data?.acquisitionValue && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.acquisitionValue)}
            </Text>
          ),
        },
      ];
    }

    if (
      type === ContractType.LetterOfCredit ||
      type === ContractType.Guarantee
    ) {
      return [
        {
          label: "Credit Limit",
          content: data?.creditLimit && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.creditLimit)}
            </Text>
          ),
        },
        {
          label: "Debit Balance",
          content: data?.debitBalance && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.debitBalance)}
            </Text>
          ),
        },
        {
          label: "Deposit Guarantee",
          content: data?.depositGuarantee && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.depositGuarantee)}
            </Text>
          ),
        },
      ];
    }

    if (type === ContractType.OtherFacilities) {
      return [
        {
          label: "Debit Balance",
          content: data?.debitBalance && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.debitBalance)}
            </Text>
          ),
        },
        {
          label: "Value In Currency Origin",
          content: data?.valueInOriginalCurrency && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.valueInOriginalCurrency)}
            </Text>
          ),
        },
      ];
    }
    return [];
  }, [data, classes, type]);
};
