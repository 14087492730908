import { Text } from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikContact } from "../../../../../types/clikContact.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";
import { getCRCurrentFlagState } from "../../utils.ts";

interface Props {
  data: ClikContact[] | null | undefined;
}

export const useCRContactsCardsInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  const { classes } = useStyles();

  return useMemo(() => {
    if (!data) {
      return null;
    }

    return data?.map((item) => ({
      label: `${getCRCurrentFlagState(item.current)} - Contact`,
      variant: item.current ? "bronze" : "blue",
      items: [
        {
          label: "Phone Number",
          content: item.phoneNumber && (
            <Link to={`tel:${item.phoneNumber}`} className={classes.infoText}>
              {item.phoneNumber}
            </Link>
          ),
        },
        {
          label: "Cell Phone Number",
          content: item.cellphoneNumber && (
            <Link
              to={`tel:${item.cellphoneNumber}`}
              className={classes.infoText}
            >
              {item.cellphoneNumber}
            </Link>
          ),
        },
        {
          label: "Email Address",
          content: item.emailAddress && (
            <Link
              to={`mailto:${item.emailAddress}`}
              className={classes.infoText}
            >
              {item.emailAddress.toLowerCase()}
            </Link>
          ),
        },
        {
          label: "Last Update Date",
          content: item.lastUpdatedDate && (
            <Text className={classes.infoText}>
              {toLocalDate(item.lastUpdatedDate)}
            </Text>
          ),
        },
      ],
    }));
  }, [data, classes]);
};
