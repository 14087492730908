import isNil from "lodash/isNil";

import { CURRENCY } from "../constants.ts";

const LANGUAGE_LOCAL = "en-US";

const PriceFormat = new Intl.NumberFormat(LANGUAGE_LOCAL, {
  minimumFractionDigits: 2,
});

const NumberFormat = new Intl.NumberFormat(LANGUAGE_LOCAL, {
  maximumFractionDigits: 2,
});

export const priceFormatter = (value: number | string) =>
  PriceFormat.format(Number(value));

export const toPrice = (value: number | string | null | undefined) => {
  if (!value) {
    return null;
  }
  return priceFormatter(value);
};

export const formatToNumber = (
  value: number | string | undefined | null
): number | undefined => {
  if (isNil(value)) {
    return;
  }
  return Number(value);
};

export const toLocalNumber = (
  value: number | string | undefined | null
): string | undefined => {
  if (isNil(value)) {
    return;
  }
  return NumberFormat.format(Number(value));
};

export const toPercent = (
  value: number | string | undefined | null
): string | undefined => {
  if (isNil(value)) {
    return;
  }
  const numberValue = Number(value) * 100;
  return `${toLocalNumber(numberValue)}%`;
};

export const parseStringToNumber = (value: string) =>
  value.replace(/\$\s?|(,*)/g, "");

export const toCurrencyPrice = (
  amount: string,
  currency?: string | null | undefined
) => {
  const curr = isNil(currency) ? CURRENCY : currency;
  return `${curr} ${toPrice(amount)}`;
};
