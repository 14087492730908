import { Button, Flex } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import { useCurrentUserContext } from "../../../../components/Auth/CurrentUserProvider.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import Title from "../../../../components/Title/Title.tsx";
import {
  CollectionActivityOrderBy,
  CreateCollectionActivityInput,
  OrderBy,
  RolePermission,
  UpdateCollectionActivityInput,
} from "../../../../graphql/generated.ts";
import { useCollectionActivities } from "../../../../hooks/api/collectionActivity/useCollectionActivities.ts";
import { useCreateCollectionActivity } from "../../../../hooks/api/collectionActivity/useCreateCollectionActivity.ts";
import { useUpdateCollectionActivity } from "../../../../hooks/api/collectionActivity/useUpdateCollectionActivity.ts";
import { useMutationNotificationWrapper } from "../../../../hooks/useMutationNotificationWrapper.tsx";
import { CollectionActivity } from "../../../../types/collectionActivity.ts";
import { getPagesCount } from "../../../../utils/pagination";
import { hasPermission } from "../../../../utils/user.ts";
import { getCollectionActivityApiSort } from "../../CollectionActivityTab/utils.ts";
import AddCollectionActivityModal from "./AddCollectionActivityModal/AddCollectionActivityModal.tsx";
import EditCollectionActivityModal from "./EditCollectionActivityModal/EditCollectionActivityModal.tsx";
import { useCollectionActivityHistoryData } from "./hooks/useCollectionActivityHistoryData.tsx";

interface CollectionActivityHistoryProps {
  invoiceId: number;
}

const CollectionActivityHistory = ({
  invoiceId,
}: CollectionActivityHistoryProps) => {
  const [selectedCollectionActivity, setSelectedCollectionActivity] =
    useState<CollectionActivity | null>();

  const [orderBy, setOrderBy] = useState<CollectionActivityOrderBy>({
    createdAt: OrderBy.Asc,
  });

  const [
    addCollectionActivityModalOpened,
    {
      open: addCollectionActivityModalOpen,
      close: addCollectionActivityModalClose,
    },
  ] = useDisclosure(false);

  const [
    editCollectionActivityModalOpened,
    {
      open: editCollectionActivityModalOpen,
      close: editCollectionActivityModalClose,
    },
  ] = useDisclosure(false);

  const [page, setPage] = useState<number>(1);

  const [
    { data: collectionActivitiesData, fetching },
    refreshCollectionActivities,
  ] = useCollectionActivities({
    filter: {
      invoiceId: {
        equals: Number(invoiceId),
      },
    },
    orderBy,
    page,
  });

  const { user } = useCurrentUserContext();

  const canAdd = hasPermission(user, RolePermission.CanAddCollectionActivity);

  const collectionActivities =
    collectionActivitiesData?.collectionActivities?.data;

  const pageCount = getPagesCount(
    collectionActivitiesData?.collectionActivities?.total
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleOpenEditCollectionActivityModal = (id: number) => {
    if (!collectionActivities) return;
    const collectionActivity = collectionActivities.find(
      (item) => item.id === id
    );
    if (!collectionActivity) return;
    setSelectedCollectionActivity(collectionActivity);
    editCollectionActivityModalOpen();
  };

  const [, createCollectionActivity] = useMutationNotificationWrapper(
    useCreateCollectionActivity(),
    {
      success: {
        message: "Collection activity has been added successfully.",
      },
    }
  );

  const [, updateCollectionActivity] = useMutationNotificationWrapper(
    useUpdateCollectionActivity(),
    {
      success: {
        message: "Collection activity has been updated successfully.",
      },
    }
  );

  const [columns, rows] = useCollectionActivityHistoryData({
    data: collectionActivities,
    onEdit: handleOpenEditCollectionActivityModal,
  });

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getCollectionActivityApiSort(sort));
    },
    [setOrderBy]
  );

  const handleCreateCollectionActivity = (
    value: Omit<CreateCollectionActivityInput, "invoiceId">
  ) => {
    const input = { invoiceId, ...value };
    createCollectionActivity({ input }).then((result) => {
      if (!result.error) {
        refreshCollectionActivities();
      }
    });
    addCollectionActivityModalClose();
  };

  const handleEditCollectionActivityModalClose = () => {
    setSelectedCollectionActivity(null);
    editCollectionActivityModalClose();
  };

  const handleUpdateCollectionActivity = (
    value: UpdateCollectionActivityInput
  ) => {
    selectedCollectionActivity &&
      updateCollectionActivity({
        id: selectedCollectionActivity?.id,
        input: value,
      }).then((result) => {
        if (!result.error) {
          refreshCollectionActivities();
        }
      });
    handleEditCollectionActivityModalClose();
  };

  return (
    <>
      <Flex direction="column" align="flex-start" gap={{ base: 16, sm: 12 }}>
        <Title size="h2">Collection Activity History</Title>
        {canAdd && (
          <Button
            uppercase
            variant="filledGolden"
            leftIcon={<SvgPlus />}
            onClick={addCollectionActivityModalOpen}
          >
            Add
          </Button>
        )}
        <Table
          columns={columns}
          rows={rows}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.asc,
          }}
          loading={fetching}
          onSortChange={handleOnSortChange}
        />
      </Flex>
      <AddCollectionActivityModal
        opened={addCollectionActivityModalOpened}
        onClose={addCollectionActivityModalClose}
        onSubmit={handleCreateCollectionActivity}
      />
      {selectedCollectionActivity && (
        <EditCollectionActivityModal
          value={selectedCollectionActivity}
          opened={editCollectionActivityModalOpened}
          onClose={handleEditCollectionActivityModalClose}
          onSubmit={handleUpdateCollectionActivity}
        />
      )}
    </>
  );
};

export default CollectionActivityHistory;
