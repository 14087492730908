import { useQuery } from "urql";

import { DashboardNextPaymentDueDocument } from "../../../graphql/generated.ts";

interface Props {
  salesTeamName?: string[] | null;
}

export const useDashboardNextPaymentDue = ({ salesTeamName }: Props) => {
  return useQuery({
    query: DashboardNextPaymentDueDocument,
    variables: { salesTeamName: salesTeamName },
  });
};
