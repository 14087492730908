import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardProps } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import {
  ClikContractGuarantee,
  ClikIdentification,
} from "../../../../../../graphql/generated.ts";
import { useStyles } from "../../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikContractGuarantee[] | null | undefined;
}
export const useCRGuarantorsDetails = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  const { classes } = useStyles();

  return useMemo(() => {
    if (!data) {
      return null;
    }
    const getGuarantorIdentities = (identity?: ClikIdentification | null) => {
      if (!identity) {
        return [];
      }

      return [
        {
          label: "Identity Type",
          content: <Text className={classes.infoText}>{identity.type}</Text>,
        },
        {
          label: "Identity Number",
          content: <Text className={classes.infoText}>{identity.number}</Text>,
        },
      ];
    };

    return data.map((item, index) => {
      return {
        label: `Detail of Guarantor ${index + 1}: ${item.guarantorType}`,
        items: [
          {
            label: "Guarantor Name as ID",
            content: (
              <Text className={classes.infoText}>{item.guarantorNameAsID}</Text>
            ),
          },
          {
            label: "Guarantor Full Name",
            content: item?.guarantorFullName && (
              <Text className={classes.infoText}>{item.guarantorFullName}</Text>
            ),
          },
          {
            label: "Percentage Guaranteed",
            content: item?.percentageGuaranteed && (
              <Text className={classes.infoText}>
                {item.percentageGuaranteed}
              </Text>
            ),
          },
          {
            label: "Information",
            content: item?.information && (
              <Text className={classes.infoText}>{item.information}</Text>
            ),
          },
          {
            label: "Address",
            content: item?.address && (
              <Text className={classes.infoText}>{item.address}</Text>
            ),
          },
          {
            label: "Provider Type",
            content: item?.providerType && (
              <Text className={classes.infoText}>{item.providerType}</Text>
            ),
          },
          {
            label: "Provider",
            content: item?.providerCode && (
              <Text className={classes.infoText}>{item.providerCode}</Text>
            ),
          },
          ...getGuarantorIdentities(item?.identity),
        ],
      };
    });
  }, [data, classes]);
};
