import {
  Button,
  Flex,
  Modal,
  NumberInput,
  ScrollArea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import { UpdateRestructureDetailInput } from "../../../graphql/generated";
import { RestructureDetail } from "../../../types/restructure.ts";
import {
  formatToNumber,
  parseStringToNumber,
  priceFormatter,
} from "../../../utils/number.ts";
import SvgCalendar from "../../Icons/Calendar";

export interface EditRestructureDetailModalProps {
  opened: boolean;
  value: RestructureDetail;
  onClose: () => void;
  onSubmit: (input: UpdateRestructureDetailInput) => void;
}

export default function EditRestructureDetailModal({
  opened = false,
  value,
  onClose,
  onSubmit,
}: EditRestructureDetailModalProps) {
  const form = useForm<Partial<UpdateRestructureDetailInput>>({
    initialValues: {
      dueDate: new Date(value.dueDate),
      amountToBePaid: formatToNumber(value.amountToBePaid),
      fee: formatToNumber(value.fee),
      outstandingPrincipal: formatToNumber(value.outstandingPrincipal),
    },
    validate: {
      dueDate: isNotEmpty("Field is required"),
      outstandingPrincipal: isNotEmpty("Field is required"),
      fee: isNotEmpty("Field is required"),
      amountToBePaid: isNotEmpty("Field is required"),
    },
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(form.values as UpdateRestructureDetailInput);
    handleClose();
  }, [form.values, handleClose, onSubmit]);

  return (
    <>
      <Modal
        opened={opened}
        title="Edit Restructure Request Details"
        scrollAreaComponent={ScrollArea.Autosize}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <TextInput
            size="m"
            disabled
            label="Invoice Number"
            placeholder="Select Invoice Number"
            value={value.invoice?.invoiceNumber}
          />

          <DatePickerInput
            required
            label="Due Date"
            placeholder="Select Due Date"
            icon={<SvgCalendar />}
            firstDayOfWeek={0}
            size="m"
            {...form.getInputProps("dueDate")}
          />

          <NumberInput
            required
            precision={2}
            parser={parseStringToNumber}
            formatter={priceFormatter}
            hideControls
            label="Outstanding Principal"
            placeholder="Enter Outstanding Principal"
            size="m"
            {...form.getInputProps("outstandingPrincipal")}
          />

          <NumberInput
            required
            precision={2}
            parser={parseStringToNumber}
            formatter={priceFormatter}
            hideControls
            label="Fee"
            placeholder="Enter Fee"
            size="m"
            {...form.getInputProps("fee")}
          />

          <NumberInput
            required
            precision={2}
            parser={parseStringToNumber}
            formatter={priceFormatter}
            hideControls
            label="Amount To Be Paid"
            placeholder="Enter Amount To Be Paid"
            size="m"
            {...form.getInputProps("amountToBePaid")}
          />

          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
