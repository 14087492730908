import { Flex, MultiSelect } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Table from "../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../components/Table/types.ts";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import {
  ClikDataFilter,
  ClikDataOrderBy,
  OrderBy,
  RolePermission,
} from "../../graphql/generated.ts";
import { useClikDataList } from "../../hooks/api/clikData/useClikDataList.ts";
import { useSendClikDataMERequest } from "../../hooks/api/clikData/useSendClikDataMERequest.ts";
import { useCompanies } from "../../hooks/api/customer/useCompanies.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { getCompanyOptions } from "../../utils/company.ts";
import { getPagesCount } from "../../utils/pagination.ts";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404.tsx";
import { useClikDataTable } from "./hooks/useClickDataTable.tsx";
import { ClikDataTableItem } from "./types.ts";
import { geClikDataApiFilter, getClikDataApiSort } from "./utils.ts";

const ClikDataPage = () => {
  const { user: currentUser } = useCurrentUserContext();

  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<ClikDataFilter>({});
  const [orderBy, setOrderBy] = useState<ClikDataOrderBy>({
    createdAt: OrderBy.Desc,
  });
  const [companyName, setCompanyName] = useState<string[] | undefined>();

  const [{ data: clikDataResponse, fetching }, refreshClikData] =
    useClikDataList({
      filter,
      orderBy,
      page,
    });

  const [, sendClikDataMERequest] = useMutationNotificationWrapper(
    useSendClikDataMERequest(),
    {
      success: {
        message: "CLIK Data ME Request has been sent successfully.",
      },
    }
  );

  const clikData = clikDataResponse?.clikDataList?.data;

  const pageCount = getPagesCount(clikDataResponse?.clikDataList?.total);

  const handleMeRequest = (id: number) => {
    sendClikDataMERequest({ id }).then(refreshClikData);
  };

  const [columns, rows] = useClikDataTable({
    data: clikData,
    onMeRequest: handleMeRequest,
  });

  const [{ data: companies }] = useCompanies();

  const hasViewAccess = hasPermission(
    currentUser,
    RolePermission.CanViewClikData
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleFilterChange = useCallback(
    (value: string[]) => {
      setCompanyName(value);
      setFilter(geClikDataApiFilter({ companyName: value }));
    },
    [setFilter, setCompanyName]
  );

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getClikDataApiSort(sort));
    },
    [setOrderBy]
  );

  const handleNavigateToClikDataDetails = (record: TableRow) => {
    if (!clikData) {
      return;
    }
    const clikItem = clikData.find(
      (item: ClikDataTableItem) => item.id === record.id
    );
    if (!clikItem) {
      return;
    }
    navigate(`${AppRoute.ClikData}/${clikItem.id}`);
  };

  if (!hasViewAccess) {
    return <Page404 />;
  }

  return (
    <>
      <Layout>
        <Flex direction="column" gap={{ base: 16, sm: 24 }}>
          <Flex direction="column" gap={16}>
            <Title size="h1">CLIK Data</Title>
            <MultiSelect
              data={getCompanyOptions(companies?.customers)}
              value={companyName}
              label="Company Name"
              placeholder="Select Company Name"
              size="m"
              maw="584px"
              searchable
              clearButtonProps={{ "aria-label": "Clear selection" }}
              onChange={handleFilterChange}
            />
          </Flex>
          <Table
            loading={fetching}
            columns={columns}
            rows={rows}
            defaultSort={{
              columnAccessor: "naeRequestDate",
              direction: SortDirection.asc,
            }}
            pagination={{
              pageCount: pageCount,
              page: page,
              onPageChange: handlePageChange,
            }}
            onSortChange={handleOnSortChange}
            onRowClick={handleNavigateToClikDataDetails}
          />
        </Flex>
      </Layout>
    </>
  );
};

export default ClikDataPage;
