import { DataTableSortStatus } from "mantine-datatable";

import {
  CustomerOrderBy,
  LimitRequestOrderBy,
  UserOrderBy,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";

export const getLimitRequestApiSort = (
  tableSort: DataTableSortStatus
): LimitRequestOrderBy => {
  const { companyName, salesName, salesTeam, cuAssignedName, ...apiSort } =
    getApiOrderBy(tableSort);
  let company: CustomerOrderBy | undefined = undefined;
  if (companyName) {
    company = { name: companyName };
  }
  if (salesName) {
    company = { salesName };
  }
  if (salesTeam) {
    company = { salesTeamName: salesTeam };
  }
  let cuAssigned: UserOrderBy | undefined = undefined;
  if (cuAssignedName) {
    cuAssigned = { name: cuAssignedName };
  }
  return {
    ...apiSort,
    company,
    cuAssigned,
  };
};
