import { Flex, Text } from "@mantine/core";
import classNames from "classnames";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { CustomerType } from "../../../../graphql/generated.ts";
import { Customer } from "../../../../types/customer.ts";
import { getDisplayedAddress } from "../../../../utils/address.ts";
import { useStyles } from "./CompanyInfo.styles.ts";
import { addressTypesLabels } from "./constants.ts";

interface CompanyInfoProps {
  customer: Customer;
  loading?: boolean;
}

const CompanyInfo = ({ customer, loading }: CompanyInfoProps) => {
  const { classes } = useStyles();

  const customerAddress = getDisplayedAddress(customer);

  const info = [
    {
      label: "Website",
      content: customer.website && (
        <Link
          className={classNames(
            classes.companyInfoText,
            classes.companyInfoColorText
          )}
          to={customer.website}
          target="_blank"
        >
          {customer.website}
        </Link>
      ),
    },
    {
      label: "Sales Name",
      content: customer.salesName && (
        <Text
          className={classNames(
            classes.companyInfoText,
            classes.companyInfoColorText
          )}
        >
          {customer.salesName}
        </Text>
      ),
    },
    {
      label: "Sales Team",
      content: customer.salesTeamName && (
        <Text
          className={classNames(
            classes.companyInfoText,
            classes.companyInfoColorText
          )}
        >
          {customer.salesTeamName}
        </Text>
      ),
    },
  ];

  const picAddresses = useMemo(() => {
    const pics = customer?.pic ?? [];
    return pics
      .filter((item) => item.type && item.type !== CustomerType.Contact)
      .map((item) => {
        const address = getDisplayedAddress(item);
        return {
          label: addressTypesLabels[item.type as CustomerType],
          content: address && (
            <Text className={classes.companyInfoText}>{address}</Text>
          ),
        };
      });
  }, [classes.companyInfoText, customer?.pic]);

  const contactAddress = [
    {
      label: "Address",
      content: customerAddress && (
        <Text className={classes.companyInfoText}>{customerAddress}</Text>
      ),
    },
  ];

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle>Company Info</SectionTitle>
      <Flex gap={20} className={classes.companyInfoWrapper}>
        <MultiFieldCard
          label="Info"
          variant="blue"
          items={info}
          loading={loading}
        />
        <MultiFieldCard
          label="Address"
          variant="blue"
          items={[...contactAddress, ...picAddresses]}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

export default CompanyInfo;
