import { MantineTheme } from "@mantine/core";

export type ThemeGradientBackground = "golden" | "blue" | "bronze" | "green";
export const themeGradientBackground = (
  theme: MantineTheme,
  variant?: ThemeGradientBackground
) => {
  if (variant === "golden") {
    return {
      background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
    };
  }
  if (variant === "blue") {
    return {
      background: `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
    };
  }
  if (variant === "bronze") {
    return {
      background: `linear-gradient(180deg, ${theme.colors.bronze[2]} 0%, ${theme.colors.bronze[3]} 100%)`,
    };
  }
  if (variant === "green") {
    return {
      background: `linear-gradient(180deg, ${theme.colors.greenIce[4]} 0%, ${theme.colors.greenIce[5]} 100%)`,
    };
  }
};
