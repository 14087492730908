import {
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import Dropzone from "../../../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../../../components/Icons/Calendar";
import {
  CollectionActivityCategory,
  CollectionActivityResultCategory,
  CreateCollectionActivityInput,
  FileResult,
} from "../../../../../graphql/generated.ts";
import { CollectionActivity } from "../../../../../types/collectionActivity.ts";
import {
  parseStringToNumber,
  priceFormatter,
} from "../../../../../utils/number.ts";
import { categoryOptions, resultCategoryOptions } from "../constants.ts";

interface AddCollectiionActivityModalProps {
  opened?: boolean;
  onClose: () => void;
  onSubmit: (value: Omit<CreateCollectionActivityInput, "invoiceId">) => void;
}

const AddCollectionActivityModal = ({
  opened = false,
  onClose,
  onSubmit,
}: AddCollectiionActivityModalProps) => {
  const form = useForm<Partial<CollectionActivity>>({
    initialValues: {
      detailCollectionActivity: "",
      resultCategory: CollectionActivityResultCategory.DataUpdate,
      resultDescription: "",
      promiseToPayAmount: "",
      promiseToPayDate: new Date(),
      attachment: null,
      category: CollectionActivityCategory.Call,
    },
    validate: {
      detailCollectionActivity: isNotEmpty(
        "Detail Collection Activity is required"
      ),
      resultCategory: isNotEmpty("Result Category is required"),
      resultDescription: isNotEmpty("Result Description is required"),
      promiseToPayAmount: isNotEmpty("Promise To Pay Amount is required"),
      promiseToPayDate: isNotEmpty("Promise To Pay Date is required"),
      category: isNotEmpty("Category is required"),
    },
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit({
      ...form.values,
      attachment: form.values.attachment?.filePath,
    } as Omit<CreateCollectionActivityInput, "invoiceId">);
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({ attachment: files[0] });
  };

  const handleRemoveFile = () => {
    form.setValues({ attachment: undefined });
  };

  return (
    <>
      <Modal
        opened={opened}
        title="Add Collection Activity"
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Flex gap={12} direction="column">
            <Select
              withinPortal
              dropdownPosition="bottom"
              required
              data={categoryOptions}
              label="Collection Activity Category"
              placeholder="Select Collection Activity Category"
              size="m"
              {...form.getInputProps("category")}
            />
            <TextInput
              required
              label="Detail Collection Activity"
              placeholder="Enter Detail Collection Activity"
              size="m"
              {...form.getInputProps("detailCollectionActivity")}
            />
            <Select
              withinPortal
              dropdownPosition="bottom"
              required
              data={resultCategoryOptions}
              label="Result Category"
              placeholder="Select Result Category"
              size="m"
              {...form.getInputProps("resultCategory")}
            />
            <Textarea
              required
              label="Result Description"
              placeholder="Enter Result Description"
              size="m"
              minRows={3}
              maxRows={3}
              autosize
              {...form.getInputProps("resultDescription")}
            />
            <NumberInput
              required
              precision={2}
              parser={parseStringToNumber}
              formatter={priceFormatter}
              hideControls
              label="Promise To Pay Amount"
              placeholder="Add Promise To Pay Amount"
              size="m"
              {...form.getInputProps("promiseToPayAmount")}
            />
            <DatePickerInput
              label="Promise To Pay Date"
              placeholder="Select Promise To Pay Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              clearable
              {...form.getInputProps("promiseToPayDate")}
            />
            <Input.Wrapper label="Attachment" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                value={form.values.attachment ? [form.values.attachment] : null}
                onUploadSuccess={handleFileUploaded}
                onRemove={handleRemoveFile}
              />
            </Input.Wrapper>
          </Flex>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              add
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default AddCollectionActivityModal;
