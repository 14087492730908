import { MantineThemeOverride } from "@mantine/core";

export const colors: MantineThemeOverride["colors"] = {
  black: [
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
  ],
  midnightBlue: [
    "#16253D",
    "#0A2C64",
    "#929DAF",
    "#4F617D",
    "#2C3C56",
    "#0C1118",
    "#060709",
    "#030304",
    "#010102",
    "#010101",
  ],
  actionIcon: [
    "#e6ebf4",
    "#D9E5F5",
    "#f1e6e6",
    "#FFE0E0",
    "#115DDA11",
    "#115DDA11",
    "#115DDA11",
    "#115DDA11",
    "#115DDA11",
    "#115DDA11",
  ],
  dusk: [
    "#002054",
    "#0C2E67",
    "#0D3069",
    "#11346F",
    "#003182",
    "#EEF4FC",
    "#D9E5F5",
    "#001840",
    "#193665",
    "#011D49",
  ],
  bronze: [
    "#CD7213",
    "#F4F1EC",
    "#E27F17",
    "#AE5900",
    "#7D5022",
    "#4F3922",
    "#33281D",
    "#221D17",
    "#171412",
    "#100E0D",
  ],
  golden: [
    "#EFB509",
    "#FEF8E7",
    "#FFFEFA",
    "#FAF1E8",
    "#f9e19d",
    "#655527",
    "#f5d36b",
    "#2F2B1F",
    "#222019",
    "#181714",
  ],
  white: [
    "#FFFFFF",
    "#ffffff1a",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
  ],
  grayIce: [
    "#B0B6BD",
    "#A1A3A5",
    "#F4F5F6",
    "#F7F8F9",
    "#e6ecf7",
    "#d2def0",
    "#F6F8FC",
    "#777878",
    "#6B6C6C",
    "#616161",
  ],
  error: [
    "#AE1414",
    "#ECE7E7",
    "#BE9E9E",
    "#B45050",
    "#592727",
    "#332222",
    "#1F1919",
    "#131111",
    "#0C0C0C",
    "#080808",
  ],
  greenIce: [
    "#E3E6E1",
    "#A4B69B",
    "#6FA456",
    "#489623",
    "#7DDE4F",
    "#4CA024",
    "#191B17",
    "#101110",
    "#0B0B0B",
    "#070707",
  ],
  contractsTable: [
    "#ABD297",
    "#FCE77C",
    "#FBB835",
    "#FF8F3D",
    "#FF6E6E",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
  ],
};
