import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikContractHistorySummary } from "../../../../../types/clikResult.ts";
import { toPrice } from "../../../../../utils/number.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikContractHistorySummary | null | undefined;
}

export const useCRContractSummaryCardInfo = ({
  data,
}: Props): MultiFieldCardItem[] => {
  const { classes } = useStyles();

  return useMemo(() => {
    if (!data) {
      return [];
    }

    return [
      {
        label: "Contracts number",
        content: data.contractsNumber && (
          <Text className={classes.infoText}>{data.contractsNumber}</Text>
        ),
      },
      {
        label: "Reporting Providers Number",
        content: data.reportingProvidersNumber && (
          <Text className={classes.infoText}>
            {data.reportingProvidersNumber}
          </Text>
        ),
      },
      {
        label: "Total Debit Balance",
        content: data.totalDebitBalance && (
          <Text className={classes.infoText}>
            {toPrice(data.totalDebitBalance)}
          </Text>
        ),
      },
      {
        label: "Total Overdue",
        content: data.totalOverdue && (
          <Text className={classes.infoText}>{toPrice(data.totalOverdue)}</Text>
        ),
      },
      {
        label: "Total Credit Limit",
        content: data.totalCreditLimit && (
          <Text className={classes.infoText}>
            {toPrice(data.totalCreditLimit)}
          </Text>
        ),
      },
      {
        label: "Total Potential Exposure",
        content: data.totalPotentialExposure && (
          <Text className={classes.infoText}>
            {toPrice(data.totalPotentialExposure)}
          </Text>
        ),
      },
      {
        label: "Currency",
        content: data.currencyDesc && (
          <Text className={classes.infoText}>{data.currencyDesc}</Text>
        ),
      },
    ];
  }, [data, classes]);
};
