import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikFootprintStatistic } from "../../../../../types/clikResult.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikFootprintStatistic | null | undefined;
}

export const useCRFootprintStatisticCardsInfo = ({
  data,
}: Props): MultiFieldCardItem[] => {
  const { classes } = useStyles();

  return useMemo(() => {
    return [
      {
        label: "1 Month",
        content: data?.footprintsCountOneMonth && (
          <Text className={classes.infoText}>
            {data.footprintsCountOneMonth}
          </Text>
        ),
      },
      {
        label: "3 Months",
        content: data?.footprintsCountThreeMonth && (
          <Text className={classes.infoText}>
            {data.footprintsCountThreeMonth}
          </Text>
        ),
      },
      {
        label: "6 Months",
        content: data?.footprintsCountSixMonth && (
          <Text className={classes.infoText}>
            {data.footprintsCountSixMonth}
          </Text>
        ),
      },
      {
        label: "12 Months",
        content: data?.footprintsCountTwelveMonth && (
          <Text className={classes.infoText}>
            {data.footprintsCountTwelveMonth}
          </Text>
        ),
      },
    ];
  }, [data, classes]);
};
