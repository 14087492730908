import { useQuery } from "urql";

import {
  GetInvoicesDocument,
  InvoiceFilter,
  InvoiceOrderBy,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api";
import { ApiHookProps } from "../types.ts";

export const useInvoices = ({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<InvoiceFilter, InvoiceOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetInvoicesDocument, variables, pause });
};
