import { Flex } from "@mantine/core";
import { useCallback, useState } from "react";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import InvoicesTable from "../../components/Invoice/InvoicesTable/InvoicesTable.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import {
  InvoiceFilter,
  OrderBy,
  RolePermission,
} from "../../graphql/generated.ts";
import { useCustomers } from "../../hooks/api/customer/useCustomers.ts";
import { useCollectors } from "../../hooks/api/user/useCollectors.ts";
import { hasPermission } from "../../utils/user.ts";
import ActivityManagementToolbar from "./ActivityManagementToolbar/ActivityManagementToolbar.tsx";
import { FiltersValue } from "./ActivityManagementToolbar/types.ts";
import { getInvoiceApiFilter } from "./utils.ts";

export default function ActivityManagementPage() {
  const [filter, setFilter] = useState<InvoiceFilter>({});

  const [{ data: collectorsData }] = useCollectors();

  const [{ data: customersData }] = useCustomers({
    filter: { parentId: null },
    orderBy: {
      name: OrderBy.Asc,
    },
  });

  const { user } = useCurrentUserContext();

  const hasAccess = hasPermission(
    user,
    RolePermission.CanAssignInvoiceCollector
  );

  const handleFilterChange = useCallback((value: FiltersValue) => {
    setFilter(getInvoiceApiFilter(value));
  }, []);

  return (
    <Layout>
      <Flex direction="column" align="flex-start" gap={{ base: 16, sm: 24 }}>
        <ActivityManagementToolbar
          collectors={collectorsData?.users.data}
          customers={customersData?.customers}
          onValueChange={handleFilterChange}
        />
        <InvoicesTable filter={filter} canEdit={hasAccess} isNavigate />
      </Flex>
    </Layout>
  );
}
