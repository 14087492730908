import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  fileName: {
    color: theme.colors.midnightBlue[0],
    fontSize: rem(14),
    fontWeight: 500,
    lineHeight: 1.3,
    textDecoration: "underline",
    cursor: "pointer",
  },
  pengurusInfoText: {
    fontSize: rem(14),
    fontWeight: 500,
    lineHeight: 1.3,
    wordBreak: "break-word",
    maxWidth: "70%",
    textAlign: "end",
    color: theme.colors.midnightBlue[0],
  },
}));
