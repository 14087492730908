import { Button, Flex, SimpleGrid } from "@mantine/core";
import { useState } from "react";

import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgEdit from "../../../components/Icons/Edit.tsx";
import { ClikCity, ClikCountry } from "../../../graphql/generated.ts";
import { useUpdateLimitRequest } from "../../../hooks/api/limitRequest/useUpdateLimitRequest.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import {
  LimitRequestCompany,
  RawLimitRequest,
} from "../../../types/limitRequest.ts";
import { useEditLimitRequestCompanyInfo } from "./hooks/useEditLimitRequestCompanyInfo.tsx";
import { useLimitRequestCompanyInfoCards } from "./hooks/useLimitRequestCompanyInfoCards.tsx";
import { formatLimitRequestToUpdateLimitRequestInput } from "./utils.ts";

interface CompanyInfoProps {
  canEdit: boolean;
  isFetching: boolean;
  limitRequest: RawLimitRequest | null | undefined;
  cities: ClikCity[] | null | undefined;
  countries: ClikCountry[] | null | undefined;
  companies: LimitRequestCompany[] | null | undefined;
  onRefresh: () => void;
}

const CompanyInfo = ({
  canEdit,
  isFetching,
  companies,
  cities,
  countries,
  limitRequest,
  onRefresh,
}: CompanyInfoProps) => {
  const [editMode, setEditMode] = useState(false);

  const [value, setValue] = useState<RawLimitRequest | null | undefined>(
    limitRequest
  );

  const [companyInfo, companyAddress, limitRequestInfo] =
    useLimitRequestCompanyInfoCards({
      limitRequest,
    });

  const [, updateLimitRequest] = useMutationNotificationWrapper(
    useUpdateLimitRequest(),
    {
      success: {
        message: "Limit Request has been updated successfully.",
      },
    }
  );

  const handleEdit = () => setEditMode(true);

  const handleCancel = () => setEditMode(false);

  const handleValueChange = (value: RawLimitRequest) => {
    setValue(value);
  };

  const handleSave = () => {
    if (!value || !limitRequest) {
      return;
    }
    const input = formatLimitRequestToUpdateLimitRequestInput(value);
    if (!input) {
      return;
    }
    updateLimitRequest({ id: Number(limitRequest.id), input }).then(() => {
      onRefresh();
      setEditMode(false);
    });
  };

  const editLimitRequestCompanyInfo = useEditLimitRequestCompanyInfo({
    limitRequest,
    cities,
    companies,
    countries,
    onSave: handleSave,
    onCancel: handleCancel,
    onChange: handleValueChange,
  });

  return (
    <>
      <Flex direction="column" gap={20}>
        {editMode ? (
          <>{editLimitRequestCompanyInfo}</>
        ) : (
          <>
            {canEdit && (
              <Button
                variant="filledGolden"
                leftIcon={<SvgEdit />}
                uppercase
                disabled={isFetching}
                onClick={handleEdit}
              >
                edit
              </Button>
            )}
            <SimpleGrid
              cols={1}
              breakpoints={[
                { minWidth: "sm", cols: 2 },
                { minWidth: "lg", cols: 3 },
              ]}
              spacing={20}
              verticalSpacing={20}
            >
              <MultiFieldCard
                label="Company Info"
                variant="blue"
                loading={isFetching}
                items={companyInfo}
              />
              <MultiFieldCard
                label="Company Address"
                variant="blue"
                loading={isFetching}
                items={companyAddress}
              />
              <MultiFieldCard
                label="Limit Request Info"
                variant="blue"
                loading={isFetching}
                items={limitRequestInfo}
              />
            </SimpleGrid>
          </>
        )}
      </Flex>
    </>
  );
};

export default CompanyInfo;
