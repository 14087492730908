import { useMemo } from "react";

import { CustomerFreezeStatus } from "../../../../graphql/generated.ts";
import { Customer } from "../../../../types/customer.ts";

interface Props {
  customer: Partial<Customer>;
}

export const useCustomerStatusInfo = ({ customer }: Props) => {
  return useMemo(() => {
    return [
      {
        label: "is freezed",
        content:
          customer.freezeStatus === CustomerFreezeStatus.Active ? "YES" : "NO",
      },
      { label: "is FPD", content: customer.isFpd ? "YES" : "NO" },
      { label: "is DPD", content: customer.isDpd ? "YES" : "NO" },
      { label: "longest DPD", content: customer.longestDpd },
    ];
  }, [customer]);
};
