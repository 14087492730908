import { pickBy } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";
import moment from "moment";

import {
  CollectionActivityFilter,
  CollectionActivityOrderBy,
  InvoiceOrderBy,
  PaymentStatusFilterOptions,
} from "../../../graphql/generated.ts";
import { getApiOrderBy } from "../../../utils/api.ts";
import { FiltersValue } from "../InvoicesFilterToolbar/types.ts";

export const getCollectionActivitiesApiFilter = (
  value: FiltersValue
): CollectionActivityFilter => {
  return pickBy({
    collectorAssignedId: value.collectorAssignedId?.length
      ? { in: value.collectorAssignedId?.map((c) => Number(c)) }
      : null,
    invoice: {
      invoiceDueDate: value.invoiceDueDate
        ? {
            equals: moment
              .utc(value.invoiceDueDate.toDateString())
              .startOf("day"),
          }
        : null,
      paymentStatus: value.paymentStatus?.length
        ? { in: value.paymentStatus as PaymentStatusFilterOptions[] }
        : null,
    },
  });
};

export const getCollectionActivityApiSort = (
  tableSort: DataTableSortStatus
): CollectionActivityOrderBy => {
  const {
    invoiceNumber,
    collectorAssigned,
    paidAmount,
    paymentStatus,
    dpd,
    ...apiSort
  } = getApiOrderBy(tableSort);
  let invoice: InvoiceOrderBy | undefined = undefined;
  if (invoiceNumber) {
    invoice = { invoiceNumber };
  }
  if (paidAmount) {
    invoice = { totalPaid: paidAmount };
  }
  if (paymentStatus) {
    invoice = { paymentStatus };
  }
  if (dpd) {
    invoice = {
      dpd,
    };
  }
  return {
    ...apiSort,
    invoice,
    collectorAssigned: collectorAssigned && { id: collectorAssigned },
  };
};
