import { omit } from "lodash";

import { UpdateLimitRequestInput } from "../../../graphql/generated.ts";
import { RawLimitRequest } from "../../../types/limitRequest.ts";

export const formatLimitRequestToUpdateLimitRequestInput = (
  limitRequest: RawLimitRequest
): UpdateLimitRequestInput | null => {
  if (
    !limitRequest.formLOI?.filePath ||
    !limitRequest.npwpCompany ||
    !limitRequest.suratPengajuanLimit?.filePath
  ) {
    return null;
  }
  const res = {
    ...limitRequest,
    cityId: Number(limitRequest.city.id),
    countryId: Number(limitRequest.country.id),
    phone: limitRequest.phone.toString(),
    postalCode: limitRequest.postalCode.toString(),
    formLOI: limitRequest.formLOI.filePath,
    npwpCompany: limitRequest.npwpCompany.filePath,
    suratPengajuanLimit: limitRequest.suratPengajuanLimit.filePath,
  };
  return omit(res, ["city", "country", "company"]) as UpdateLimitRequestInput;
};
