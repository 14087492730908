import { Text } from "@mantine/core";

import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikNegativeEvent } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikNegativeEvent[] | null | undefined;
}

export const useCRNegativeEventCardInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  const { classes } = useStyles();

  if (!data) {
    return null;
  }

  return data?.map((event, index) => ({
    label: `Negative Event ${index + 1}`,
    variant: "bronze",
    items: [
      {
        label: "Event Code",
        content: event?.eventCode && (
          <Text className={classes.infoText}>{event.eventCode}</Text>
        ),
      },
      {
        label: "Event Detail",
        content: event?.eventDetail && (
          <Text className={classes.infoText}>{event.eventDetail}</Text>
        ),
      },
      {
        label: "Event Date",
        content: event?.eventDate && (
          <Text className={classes.infoText}>
            {toLocalDate(event.eventDate)}
          </Text>
        ),
      },
      {
        label: "Event Expiry Date",
        content: event?.eventExpiryDate && (
          <Text className={classes.infoText}>
            {toLocalDate(event.eventExpiryDate)}
          </Text>
        ),
      },
      {
        label: "Provider Type",
        content: event?.providerType && (
          <Text className={classes.infoText}>{event.providerType}</Text>
        ),
      },
      {
        label: "Provider",
        content: event?.provider && (
          <Text className={classes.infoText}>{event.provider}</Text>
        ),
      },
      {
        label: "Reference Date",
        content: event?.referenceDate && (
          <Text className={classes.infoText}>
            {toLocalDate(event.referenceDate)}
          </Text>
        ),
      },
    ],
  }));
};
