import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  companyInfoText: {
    fontSize: rem(14),
    fontWeight: 500,
    lineHeight: 1.3,
    wordBreak: "break-word",
    maxWidth: "70%",
    textAlign: "end",
    color: theme.colors.midnightBlue[0],
  },
  buttonsWrapper: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    boxShadow: "0px 0px 12px 0px rgba(55, 65, 82, 0.18)",
    background: theme.colors.white[0],
    padding: `${rem(24)} ${rem(20)}`,
    zIndex: 3,
  },
}));
