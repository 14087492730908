import {
  CollectionActivityCategory,
  CollectionActivityResultCategory,
} from "../../../../graphql/generated.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

export const categoryOptions = Object.values(CollectionActivityCategory).map(
  (item) => ({
    value: item,
    label: toHumanReadable(item),
  })
);

export const resultCategoryOptions = Object.values(
  CollectionActivityResultCategory
).map((item) => ({
  value: item,
  label: toHumanReadable(item),
}));
