import { SelectItem } from "@mantine/core/lib/Select/types";
import { DataTableSortStatus } from "mantine-datatable";

import { CustomerOrderBy, InvoiceOrderBy } from "../../../graphql/generated";
import { Collector } from "../../../types/user.ts";
import { getApiOrderBy } from "../../../utils/api";

export const getCollectorOptions = (
  collectors: Collector[] | null | undefined
): SelectItem[] => {
  return (
    collectors?.map((user) => ({
      value: String(user.id),
      label: user.name ?? user.email,
    })) ?? []
  );
};

export const getInvoiceApiSort = (
  tableSort: DataTableSortStatus
): InvoiceOrderBy => {
  const {
    customerName,
    collectorAssigned,
    customerPhone,
    companyAddress,
    // limitUsed,
    // hasException,
    ...apiSort
  } = getApiOrderBy(tableSort);
  let customer: CustomerOrderBy | undefined = undefined;
  if (customerName) {
    customer = { name: customerName };
  }
  if (customerPhone) {
    customer = { phone: customerPhone };
  }
  if (companyAddress) {
    customer = { city: companyAddress };
  }
  // if (limitUsed) {
  //   customer = { limitDetail: { limitName: limitUsed } };
  // }
  // if (hasException) {
  //   customer = { limitDetail: { isCp: hasException } };
  // }
  return {
    ...apiSort,
    customer,
    collectorAssigned: collectorAssigned && { id: collectorAssigned },
  };
};
