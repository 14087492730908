import { useQuery } from "urql";

import { DashboardNextMonthDueDocument } from "../../../graphql/generated.ts";

interface Props {
  salesTeamName?: string[] | null;
}

export const useDashboardNextMonthDue = ({ salesTeamName }: Props) => {
  return useQuery({
    query: DashboardNextMonthDueDocument,
    variables: { salesTeamName: salesTeamName },
  });
};
