import { Flex } from "@mantine/core";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { QueryDashboardNextMonthDueArgs } from "../../../graphql/generated.ts";
import { useDashboardNextMonthDue } from "../../../hooks/api/dashboardNextMonthDue/useDashboardNextMonthDue.ts";
import { useStyles } from "../CollectionDashboardPage.styles.ts";
import { useNextMonthDueTeamTableData } from "./hooks/useNextMonthDueTeamTableData.tsx";

interface NextMonthDuePerSalesTeamProps {
  filter: QueryDashboardNextMonthDueArgs["salesTeamName"];
}

const NextMonthDuePerSalesTeam = ({
  filter,
}: NextMonthDuePerSalesTeamProps) => {
  const { classes } = useStyles();

  const [{ data, fetching }] = useDashboardNextMonthDue({
    salesTeamName: filter,
  });

  const [columns, rows] = useNextMonthDueTeamTableData({
    data: data?.dashboardNextMonthDue,
  });

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle variant="bronze" flex={0} className={classes.sectionTitle}>
        Next Month Due Invoice per Sales team
      </SectionTitle>

      <Table
        columns={columns}
        rows={rows}
        minWidth={"100%"}
        loading={fetching}
      />
    </Flex>
  );
};

export default NextMonthDuePerSalesTeam;
