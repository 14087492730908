import { Flex } from "@mantine/core";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { QueryDashboardNextPaymentDueArgs } from "../../../graphql/generated.ts";
import { useDashboardNextPaymentDue } from "../../../hooks/api/dashboardNextPaymentDue/useDashboardNextPaymentDue.ts";
import { useStyles } from "../CollectionDashboardPage.styles.ts";
import { useNextPaymentDueMonthTableData } from "./hooks/useNextPaymentDueMonthTableData.tsx";

interface NextPaymentDuePerMonthProps {
  filter: QueryDashboardNextPaymentDueArgs["salesTeamName"];
}

const NextPaymentDuePerMonth = ({ filter }: NextPaymentDuePerMonthProps) => {
  const { classes } = useStyles();

  const [{ data, fetching }] = useDashboardNextPaymentDue({
    salesTeamName: filter,
  });

  const [columns, rows] = useNextPaymentDueMonthTableData({
    data: data?.dashboardNextPaymentDue,
  });

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle variant="bronze" flex={0} className={classes.sectionTitle}>
        Next Payment Due Per Month
      </SectionTitle>

      <Table
        columns={columns}
        rows={rows}
        minWidth={"100%"}
        loading={fetching}
      />
    </Flex>
  );
};

export default NextPaymentDuePerMonth;
