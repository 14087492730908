import { Button, Flex, Input, Modal, ScrollArea, Select } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import {
  ApproveRestructureInput,
  FileResult,
} from "../../../graphql/generated.ts";
import { Restructure } from "../../../types/restructure.ts";
import Dropzone from "../../Dropzone/Dropzone.tsx";
import { restructureApprovalStatusOptions } from "../constants.ts";

export interface EditRestructureModalProps {
  opened: boolean;
  value: Restructure;
  onClose: () => void;
  onSubmit: (input: ApproveRestructureInput) => void;
}

const EditRestructureModal = ({
  opened = false,
  value,
  onClose,
  onSubmit,
}: EditRestructureModalProps) => {
  const form = useForm({
    initialValues: {
      approvalStatus: value.approvalStatus,
      signedDocument: value.signedDocument,
    },
    validate: {
      approvalStatus: isNotEmpty("Field is required"),
    },
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      ...form.values,
      signedDocument: form.values.signedDocument?.filePath,
    } as ApproveRestructureInput);
    handleClose();
  }, [form.values, handleClose, onSubmit]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({ signedDocument: files[0] });
  };

  const handleRemoveFile = () => {
    form.setValues({ signedDocument: null });
  };

  return (
    <>
      <Modal
        opened={opened}
        title="Edit Restructure Request"
        scrollAreaComponent={ScrollArea.Autosize}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Select
            size="m"
            placeholder="Select Approval Status"
            label="Approval Status"
            data={restructureApprovalStatusOptions}
            required
            {...form.getInputProps("approvalStatus")}
          />
          <Input.Wrapper label="Signed document" size="m">
            <Dropzone
              value={
                form.values.signedDocument ? [form.values.signedDocument] : null
              }
              maxFiles={1}
              onUploadSuccess={handleFileUploaded}
              onRemove={handleRemoveFile}
            />
          </Input.Wrapper>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default EditRestructureModal;
