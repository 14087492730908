import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { useStyles } from "../../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
}
export const useCRRestructuringDetailsOfContract = ({
  data,
}: Props): MultiFieldCardItem[] => {
  const { classes } = useStyles();

  return useMemo(() => {
    return [
      {
        label: "Restructuring Plan",
        content: data?.restructuringPlan && (
          <Text className={classes.infoText}>
            {toLocalDate(data.restructuringPlan)}
          </Text>
        ),
      },
      {
        label: "Initial Restructuring Date",
        content: data?.initialRestructuringDate && (
          <Text className={classes.infoText}>
            {toLocalDate(data.initialRestructuringDate)}
          </Text>
        ),
      },
      {
        label: "Final Restructuring Date",
        content: data?.finalRestructuringDate && (
          <Text className={classes.infoText}>
            {data.finalRestructuringDate}
          </Text>
        ),
      },
      {
        label: "Frequency of Restructuring",
        content: data?.frequencyOfRestructuring && (
          <Text className={classes.infoText}>
            {data.frequencyOfRestructuring}
          </Text>
        ),
      },
    ];
  }, [data, classes]);
};
