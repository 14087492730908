import { SimpleGrid } from "@mantine/core";

import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { UpdateCustomerInput } from "../../../graphql/generated.ts";
import { Customer } from "../../../types/customer.ts";
import { CustomerCollectionSummary } from "../../../types/customerCollectionSummary.ts";
import { Collector } from "../../../types/user.ts";
import { useStyles } from "./ActivityManagementCards.styles.ts";
import { useCollectionSummaryInfo } from "./hooks/useCollectionSummaryInfo.tsx";
import { useCustomerStatusInfo } from "./hooks/useCustomerStatusInfo.tsx";

interface ActivityManagementCardsProps {
  customer: Partial<Customer>;
  collectionSummary: Partial<CustomerCollectionSummary>;
  collectors: Collector[];
  onChange: (value: Partial<UpdateCustomerInput>) => void;
}

const ActivityManagementCards = ({
  customer,
  collectionSummary,
  collectors,
  onChange,
}: ActivityManagementCardsProps) => {
  const { classes } = useStyles();

  const customerStatusInfo = useCustomerStatusInfo({ customer });
  const collectionSummaryInfo = useCollectionSummaryInfo({
    collectionSummary,
    collectors,
    customer,
    onChange,
  });

  return (
    <SimpleGrid
      cols={3}
      spacing={20}
      verticalSpacing={16}
      className={classes.columnsWrapper}
    >
      <MultiFieldCard label="Customer statuses" items={customerStatusInfo} />
      <MultiFieldCard
        label="Collection Summary"
        items={collectionSummaryInfo}
      />
    </SimpleGrid>
  );
};

export default ActivityManagementCards;
