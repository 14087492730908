import { createStyles, MantineTheme, rem } from "@mantine/core";

import { themeGradientBackground } from "../../../utils/themeGradientBackground.ts";

export const useStyles = createStyles(
  (
    theme: MantineTheme,
    { variant }: { variant: "golden" | "blue" | "bronze" | "green" }
  ) => ({
    wrapper: {
      boxShadow: "0px 2px 19px 0px rgba(55, 65, 82, 0.10)",
      width: "100%",
      height: "100%",
    },
    colsWrapper: {
      "@media (max-width: 74em)": {
        flexDirection: "column",
        gap: "unset",
      },
    },
    pin: {
      width: rem(12),
      height: rem(12),
      ...themeGradientBackground(theme, variant),
    },
    label: {
      fontSize: rem(14),
      lineHeight: 1.5,
      textTransform: "capitalize",
      fontWeight: 600,
      color: theme.colors.midnightBlue[0],
    },
    itemColumn: {
      flexDirection: "column",
      alignItems: "start",
      gap: rem(4),
      width: "100%",
    },
    itemLabel: {
      fontSize: rem(14),
      lineHeight: 1.5,
      textTransform: "capitalize",
    },
    itemContentText: {
      fontSize: rem(14),
      fontWeight: 500,
      lineHeight: 1.5,
      wordBreak: "break-all",
    },
  })
);
