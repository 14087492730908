import { Badge, Flex, Switch, Text } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import ActionIcon from "../../../../components/ActionIcon/ActionIcon.tsx";
import { useCurrentUserContext } from "../../../../components/Auth/CurrentUserProvider.tsx";
import Edit from "../../../../components/Icons/Edit.tsx";
import {
  PengurusStatus,
  RolePermission,
} from "../../../../graphql/generated.ts";
import { RawPengurus } from "../../../../types/pengurus.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { hasPermission } from "../../../../utils/user.ts";
import { useStyles } from "../PengurusInfo.styles.ts";

interface Props {
  penguruses: RawPengurus[] | null | undefined;
  onEdit: (value: RawPengurus, index: number) => void;
  onStatusChange: (id: number, value: PengurusStatus) => void;
}

export const usePengurusesCardInfo = ({
  penguruses,
  onStatusChange,
  onEdit,
}: Props) => {
  const { classes } = useStyles();

  const { user: currentUser } = useCurrentUserContext();

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditLimitRequest
  );

  const handleStatusChange = useCallback(
    (item: RawPengurus) => {
      if (!item?.id) {
        return;
      }
      const newStatus =
        item.status === PengurusStatus.Active
          ? PengurusStatus.Inactive
          : PengurusStatus.Active;
      onStatusChange(item.id, newStatus);
    },
    [onStatusChange]
  );

  return useMemo(() => {
    return penguruses?.map((item, index) => ({
      cardControl: (
        <Flex gap={12}>
          <Badge
            size="s"
            variant={
              item.status === PengurusStatus.Active ? "active" : "inactive"
            }
          >
            {toHumanReadable(item.status)}
          </Badge>
          {hasEditAccess && (
            <ActionIcon
              disabled={item.status === PengurusStatus.Inactive}
              spaces={false}
              onClick={() => onEdit(item, index)}
            >
              <Edit />
            </ActionIcon>
          )}
        </Flex>
      ),
      customVariant: (
        <Flex gap={12}>
          {hasEditAccess && (
            <Switch
              variant="yellow"
              checked={item.status === PengurusStatus.Active}
              radius={2}
              onClick={() => handleStatusChange(item)}
            />
          )}
          <Text>{item.name}</Text>
        </Flex>
      ),
      items: [
        {
          label: "Pengurus Name",
          content: (
            <Text className={classes.pengurusInfoText}>{item.name}</Text>
          ),
        },
        {
          label: "KTP Pengurus",
          content: (
            <Link
              className={classes.fileName}
              to={item.ktpPengurus.url}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </Link>
          ),
        },
        {
          label: "Gender",
          content: (
            <Text className={classes.pengurusInfoText}>
              {toHumanReadable(item.gender)}
            </Text>
          ),
        },
        {
          label: "Date of Birth",
          content: (
            <Text className={classes.pengurusInfoText}>
              {toLocalDate(item.birthDate)}
            </Text>
          ),
        },
        {
          label: "KTP Number",
          content: (
            <Text className={classes.pengurusInfoText}>{item.ktpNumber}</Text>
          ),
        },
        {
          label: "Phone",
          content: (
            <Text className={classes.pengurusInfoText}>{item.phone}</Text>
          ),
        },
        {
          label: "KTP Address",
          content: (
            <Text className={classes.pengurusInfoText}>{item.ktpAddress}</Text>
          ),
        },
        {
          label: "Sub-district",
          content: (
            <Text className={classes.pengurusInfoText}>{item.subDistrict}</Text>
          ),
        },
        {
          label: "District",
          content: (
            <Text className={classes.pengurusInfoText}>{item.district}</Text>
          ),
        },
        {
          label: "City",
          content: (
            <Text className={classes.pengurusInfoText}>{item.city.name}</Text>
          ),
        },
        {
          label: "Postal Code",
          content: (
            <Text className={classes.pengurusInfoText}>{item.postalCode}</Text>
          ),
        },
        {
          label: "Country",
          content: (
            <Text className={classes.pengurusInfoText}>
              {item.country.name}
            </Text>
          ),
        },
      ],
    }));
  }, [
    penguruses,
    hasEditAccess,
    classes.pengurusInfoText,
    classes.fileName,
    onEdit,
    handleStatusChange,
  ]);
};
