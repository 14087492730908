import { createStyles, rem } from "@mantine/core";

import { themeGradientBackground } from "../../../utils/themeGradientBackground.ts";

export const useStyles = createStyles(
  (
    theme,
    { variant }: { variant: "golden" | "blue" | "bronze" | "green" }
  ) => ({
    wrapper: {
      boxShadow: "0px 2px 19px 0px rgba(55, 65, 82, 0.10)",
      height: "100%",
    },
    pin: {
      width: rem(12),
      height: rem(12),
      ...themeGradientBackground(theme, variant),
    },
    label: {
      fontSize: rem(14),
      lineHeight: 1.5,
      textTransform: "capitalize",
    },
    value: {
      fontFamily: "League Spartan",
      fontWeight: 500,
      fontSize: rem(24),
      lineHeight: 1.5,
      color: theme.colors.midnightBlue[0],
    },
  })
);
