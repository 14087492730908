import { RolePermission } from "../graphql/generated.ts";
import { User } from "../types/user.ts";

export const getUserDisplayName = (
  user: User | undefined | null
): string | null => {
  if (!user) {
    return null;
  }
  return user.name ?? user.email;
};

export const hasPermission = (
  user: User | undefined | null,
  permission: RolePermission
): boolean => {
  return user?.permissions?.includes(permission) ?? false;
};
