import {
  Button,
  Flex,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import { CreateRestructureDetailInput } from "../../../graphql/generated";
import { Invoice } from "../../../types/invoice";
import { parseStringToNumber, priceFormatter } from "../../../utils/number.ts";
import SvgCalendar from "../../Icons/Calendar";
import { invoiceIdOptions } from "../utils";

export interface AddRestructureDetailModalProps {
  restructureId: number;
  opened: boolean;
  invoices: Invoice[];
  onClose: () => void;
  onSubmit: (input: CreateRestructureDetailInput) => void;
}

export default function AddRestructureDetailModal({
  restructureId,
  opened = false,
  invoices,
  onClose,
  onSubmit,
}: AddRestructureDetailModalProps) {
  const form = useForm<Partial<CreateRestructureDetailInput>>({
    validate: {
      invoiceId: isNotEmpty("Field is required"),
      dueDate: isNotEmpty("Field is required"),
      outstandingPrincipal: isNotEmpty("Field is required"),
      fee: isNotEmpty("Field is required"),
      amountToBePaid: isNotEmpty("Field is required"),
    },
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    const input = {
      ...form.values,
      invoiceId: Number(form.values.invoiceId),
      restructureId,
    } as CreateRestructureDetailInput;
    onSubmit(input);
    handleClose();
  }, [form.values, handleClose, onSubmit, restructureId]);

  return (
    <>
      <Modal
        opened={opened}
        title="Add Restructure Request Details"
        scrollAreaComponent={ScrollArea.Autosize}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Select
            size="m"
            placeholder="Select Invoice Number"
            label="Invoice Number"
            data={invoiceIdOptions(invoices)}
            {...form.getInputProps("invoiceId")}
          />

          <DatePickerInput
            required
            label="Due Date"
            placeholder="Select Due Date"
            icon={<SvgCalendar />}
            firstDayOfWeek={0}
            size="m"
            {...form.getInputProps("dueDate")}
          />

          <NumberInput
            required
            precision={2}
            parser={parseStringToNumber}
            formatter={priceFormatter}
            hideControls
            label="Outstanding Principal"
            placeholder="Enter Outstanding Principal"
            size="m"
            {...form.getInputProps("outstandingPrincipal")}
          />

          <NumberInput
            required
            precision={2}
            parser={parseStringToNumber}
            formatter={priceFormatter}
            hideControls
            label="Fee"
            placeholder="Enter Fee"
            size="m"
            {...form.getInputProps("fee")}
          />

          <NumberInput
            required
            hideControls
            precision={2}
            parser={parseStringToNumber}
            formatter={priceFormatter}
            label="Amount To Be Paid"
            placeholder="Enter Amount To Be Paid"
            size="m"
            {...form.getInputProps("amountToBePaid")}
          />

          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
