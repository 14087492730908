import { MantineThemeOverride, rem } from "@mantine/core";

import actionIconTheme from "./actionIcon.ts";
import appShellTheme from "./appShell.ts";
import badgeTheme from "./badge.ts";
import breadcrumbsTheme from "./breadcrumbs.ts";
import buttonTheme from "./button.ts";
import { colors } from "./colors";
import dateInputTheme from "./dateInput.ts";
import dropzoneTheme from "./dropzone.ts";
import inputWrapperTheme from "./input.ts";
import modalTheme from "./modal.ts";
import multiSelectTheme from "./multiselect.ts";
import navLinkTheme from "./navlink.ts";
import notificationTheme from "./notification.ts";
import paginationTheme from "./pagination.ts";
import popoverTheme from "./popover.ts";
import scrollAreaTheme from "./scrollArea.ts";
import selectTheme from "./select.ts";
import spoilerTheme from "./spoiler.ts";
import switchTheme from "./switch.ts";
import tableTheme from "./table.ts";
import tabsTheme from "./tabs.ts";
import tooltipTheme from "./tooltip.ts";

export const theme: MantineThemeOverride = {
  colors,
  fontFamily: "Montserrat",
  headings: {
    fontFamily: "League Spartan",
    sizes: {
      h1: { fontWeight: "600", fontSize: rem(32), lineHeight: "1.5" },
      h2: { fontWeight: "600", fontSize: rem(20), lineHeight: "1.5" },
      h3: { fontWeight: "500", fontSize: rem(22), lineHeight: "1.5" },
    },
  },
  components: {
    Input: inputWrapperTheme(),
    TextInput: inputWrapperTheme(),
    NumberInput: inputWrapperTheme(),
    InputWrapper: inputWrapperTheme(),
    Button: buttonTheme(),
    Table: tableTheme(),
    Pagination: paginationTheme(),
    Select: selectTheme(),
    NavLink: navLinkTheme(),
    Modal: modalTheme(),
    AppShell: appShellTheme(),
    ScrollArea: scrollAreaTheme(),
    Notification: notificationTheme(),
    MultiSelect: multiSelectTheme(),
    DatePickerInput: dateInputTheme(),
    Tabs: tabsTheme(),
    ActionIcon: actionIconTheme(),
    Breadcrumbs: breadcrumbsTheme(),
    Dropzone: dropzoneTheme(),
    Spoiler: spoilerTheme(),
    Tooltip: tooltipTheme(),
    Popover: popoverTheme(),
    Badge: badgeTheme(),
    Switch: switchTheme(),
  },
};
