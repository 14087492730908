import { SelectItem } from "@mantine/core/lib/Select/types";

import { LimitRequestCompany } from "../types/limitRequest.ts";

export const getCompanyOptions = (
  companies: LimitRequestCompany[] | null | undefined
): SelectItem[] => {
  return (
    companies?.map((company) => ({
      value: company.id.toString(),
      label: company.name,
    })) ?? []
  );
};
