import { CSSObject, MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const defaultTabs = (theme: MantineTheme) => ({
  tabsList: {
    gap: rem(20),
    borderColor: theme.colors.grayIce[0],

    "@media (max-width: 74em)": {
      gap: rem(12),
    },

    "@media (max-width: 50em)": {
      paddingBottom: rem(16),
    },
  },
  tab: {
    padding: `${rem(8)} ${rem(28)}`,
    fontFamily: "League Spartan",
    fontSize: rem(20),
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.colors.dusk[0],
    border: 0,
    background: theme.colors.grayIce[4],
    marginBottom: 0,
    textTransform: "capitalize" as CSSObject["textTransform"],

    "@media (max-width: 74em)": {
      fontSize: rem(16),
      padding: `${rem(8)} ${rem(12)}`,
    },

    "&: hover": {
      background: theme.colors.grayIce[5],
    },

    "&[data-active]": {
      background: theme.colors.white[0],
      borderTop: `1px solid ${theme.colors.grayIce[0]}`,
      borderLeft: `1px solid ${theme.colors.grayIce[0]}`,
      borderRight: `1px solid ${theme.colors.grayIce[0]}`,
      color: theme.colors.dusk[0],

      "@media (max-width: 50em)": {
        border: `1px solid ${theme.colors.grayIce[0]}`,
      },

      "&: hover": {
        borderColor: theme.colors.grayIce[0],
      },
    },
  },
  panel: {
    borderRadius: rem(3),
    padding: rem(20),
    borderLeft: `1px solid ${theme.colors.grayIce[0]}`,
    borderRight: `1px solid ${theme.colors.grayIce[0]}`,
    borderBottom: `1px solid ${theme.colors.grayIce[0]}`,

    "@media (max-width: 48em)": {
      border: `1px solid ${theme.colors.grayIce[0]}`,
    },
  },
  tabLabel: {
    width: "100%",
  },
});

export default function tabsTheme(): ThemeComponent {
  return {
    styles: (theme) => defaultTabs(theme),
  };
}
