import { omit } from "lodash";

import {
  CreateLimitRequestInput,
  CreateLimitRequestPengurusInput,
  PengurusStatus,
} from "../../graphql/generated.ts";
import { RawLimitRequest } from "../../types/limitRequest.ts";
import { RawPengurus } from "../../types/pengurus.ts";

export const formatPenfurusToCreatePengurusInput = (
  penguruses: RawPengurus[]
): CreateLimitRequestPengurusInput[] => {
  return penguruses.map((item): CreateLimitRequestPengurusInput => {
    const pengurus = {
      ...item,
      cityId: Number(item.city.id),
      countryId: Number(item.country.id),
      ktpPengurus: item.ktpPengurus.filePath,
      status: PengurusStatus.Active,
    };
    return omit(pengurus, [
      "city",
      "country",
    ]) as CreateLimitRequestPengurusInput;
  });
};

export const formatLimitRequestToCreateLimitRequestInput = (
  limitRequest: RawLimitRequest
): CreateLimitRequestInput | null => {
  if (
    !limitRequest.formLOI?.filePath ||
    !limitRequest.npwpCompany ||
    !limitRequest.suratPengajuanLimit?.filePath ||
    !limitRequest.penguruses
  ) {
    return null;
  }

  const res = {
    ...limitRequest,
    cityId: Number(limitRequest.city.id),
    companyId: Number(limitRequest.company.id),
    countryId: Number(limitRequest.country.id),
    phone: limitRequest.phone.toString(),
    postalCode: limitRequest.postalCode.toString(),
    formLOI: limitRequest.formLOI.filePath,
    npwpCompany: limitRequest.npwpCompany.filePath,
    suratPengajuanLimit: limitRequest.suratPengajuanLimit.filePath,
    penguruses: formatPenfurusToCreatePengurusInput(limitRequest.penguruses),
  };
  return omit(res, [
    "city",
    "country",
    "company",
    "cuAssigned",
  ]) as CreateLimitRequestInput;
};
