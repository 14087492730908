import { useQuery } from "urql";

import {
  ClikResultFilter,
  ClikResultOrderBy,
  GetClikResultsDocument,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useClikResults = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<ClikResultFilter, ClikResultOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetClikResultsDocument, variables });
};
