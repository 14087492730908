import { useQuery } from "urql";

import {
  ClikGrantedGuaranteeFilter,
  ClikGrantedGuaranteeOrderBy,
  GetClikGrantedGuaranteesDocument,
} from "../../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../../utils/api.ts";
import { ApiHookProps } from "../../types.ts";

export const useClikGrantedGuarantees = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<ClikGrantedGuaranteeFilter, ClikGrantedGuaranteeOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetClikGrantedGuaranteesDocument, variables });
};
