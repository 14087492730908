import { Select, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import { TableData, TableRow } from "../../../components/Table/types.ts";
import {
  UpdateUserInput,
  UserRole,
  UserStatus,
} from "../../../graphql/generated.ts";
import { User } from "../../../types/user.ts";
import { toLocalDateTime } from "../../../utils/date.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import { userRoles, userStatuses } from "../constants.ts";
import { useStyles } from "../UserAccessSetting.styles.ts";

interface Props {
  data: User[] | null | undefined;
  canEdit?: boolean;
  onChange: (id: number, value: UpdateUserInput) => void;
}

export function useUsersTableData({
  data,
  canEdit = false,
  onChange,
}: Props): TableData {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    { accessor: "email", sortable: true, width: 240 },
    { accessor: "role", sortable: true, width: 200 },
    { accessor: "status", sortable: true, width: 120 },
    {
      accessor: "lastAuthenticated",
      title: "Last Authenticated",
      sortable: true,
      width: 200,
    },
    { accessor: "createdAt", title: "Created Date", sortable: true },
  ];

  const handleOnChange = useCallback(
    (id: number, value: UpdateUserInput) => {
      onChange(id, value);
    },
    [onChange]
  );

  const rows = useMemo(() => {
    return data?.map((user) => {
      return {
        email: <Text className={classes.emailWrapper}>{user.email}</Text>,
        role: canEdit ? (
          <Select
            key={user.id}
            data={userRoles}
            value={user.role}
            placeholder="Select a role"
            size="s"
            withinPortal
            onChange={(value: UserRole) =>
              handleOnChange(user.id, { role: value, status: user.status })
            }
          />
        ) : (
          toHumanReadable(user.role)
        ),
        status: canEdit ? (
          <Select
            key={user.id}
            data={userStatuses}
            value={user.status}
            placeholder="Select a status"
            size="s"
            onChange={(value: UserStatus) =>
              handleOnChange(user.id, { status: value, role: user.role })
            }
          />
        ) : (
          toHumanReadable(user.status)
        ),
        lastAuthenticated:
          user.lastAuthenticated && toLocalDateTime(user.lastAuthenticated),
        createdAt: toLocalDateTime(user.createdAt),
      };
    });
  }, [data, classes, canEdit, handleOnChange]);
  return [columns, rows];
}
