import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikResult } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";

interface Props {
  clikResult: ClikResult;
}

export interface CRCompanyInfo {
  subject: MultiFieldCardItem[];
  establishmentData: MultiFieldCardItem[];
  applicationSummary: MultiFieldCardItem[];
}

export const useCompanyResultInfoCards = ({
  clikResult,
}: Props): CRCompanyInfo => {
  const { classes } = useStyles();

  const subject = useMemo(() => {
    return [
      {
        label: "CB Subject Code",
        content: clikResult?.cbScore && (
          <Text className={classes.infoText}>{clikResult.cbScore}</Text>
        ),
      },
      {
        label: "Provider Subject No",
        content: clikResult?.companySubject?.providerSubjectNumber && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.providerSubjectNumber}
          </Text>
        ),
      },
      {
        label: "Last Update Date",
        content: clikResult?.companySubject?.lastUpdatedDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.companySubject.lastUpdatedDate)}
          </Text>
        ),
      },
      {
        label: "Go Public",
        content: clikResult?.companySubject?.goPublic && (
          <Text className={classes.infoText}>
            {toHumanReadable(clikResult.companySubject.goPublic)}
          </Text>
        ),
      },
      {
        label: "Trade Name",
        content: clikResult?.companySubject?.tradeName && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.tradeName}
          </Text>
        ),
      },
      {
        label: "Company Category",
        content: clikResult?.companySubject?.companyCategory && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.companyCategory}
          </Text>
        ),
      },
      {
        label: "Legal Form",
        content: clikResult?.companySubject?.legalForm && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.legalForm}
          </Text>
        ),
      },
      {
        label: "Economic Sector",
        content: clikResult?.companySubject?.economicSector && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.economicSector}
          </Text>
        ),
      },
      {
        label: "Rating",
        content: clikResult?.companySubject?.rating && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.rating}
          </Text>
        ),
      },
      {
        label: "Rating Institution",
        content: clikResult?.companySubject?.ratingInstitution && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.ratingInstitution}
          </Text>
        ),
      },
      {
        label: "Rating Date",
        content: clikResult?.companySubject?.ratingDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.companySubject.ratingDate)}
          </Text>
        ),
      },
      {
        label: "Shareholders Counter",
        content: clikResult?.companySubject?.shareholdersCount && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.shareholdersCount}
          </Text>
        ),
      },
    ];
  }, [classes, clikResult]);

  const establishmentData = useMemo(() => {
    return [
      {
        label: "Place of Establishment",
        content: clikResult?.companySubject?.establishment
          ?.placeOfEstablishment && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.establishment.placeOfEstablishment}
          </Text>
        ),
      },
      {
        label: "Deed Number of Establishment",
        content: clikResult?.companySubject?.establishment
          ?.deedNumberOfEstablishment && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.establishment.deedNumberOfEstablishment}
          </Text>
        ),
      },
      {
        label: "Deed Date of Establishment",
        content: clikResult?.companySubject?.establishment
          ?.deedDateOfEstablishment && (
          <Text className={classes.infoText}>
            {clikResult.companySubject.establishment.deedDateOfEstablishment}
          </Text>
        ),
      },
      {
        label: "Recently Amendment Deed Number",
        content: clikResult?.companySubject?.establishment
          ?.recentlyAmendmentDeedNumber && (
          <Text className={classes.infoText}>
            {
              clikResult.companySubject.establishment
                .recentlyAmendmentDeedNumber
            }
          </Text>
        ),
      },
      {
        label: "Date of Final Amendment Deed",
        content: clikResult?.companySubject?.establishment
          ?.dateofFinalAmendmentDeedNumber && (
          <Text className={classes.infoText}>
            {toLocalDate(
              clikResult.companySubject.establishment
                .dateofFinalAmendmentDeedNumber
            )}
          </Text>
        ),
      },
    ];
  }, [classes, clikResult]);

  const applicationSummary = useMemo(() => {
    return [
      {
        label: "CB Contract Code",
        content: clikResult?.applicationSummary?.cbContractCode && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.cbContractCode}
          </Text>
        ),
      },
      {
        label: "Contract Type",
        content: clikResult?.applicationSummary?.contractType && (
          <Text className={classes.infoText}>
            {toHumanReadable(clikResult.applicationSummary.contractType)}
          </Text>
        ),
      },
      {
        label: "Contract Phase",
        content: clikResult?.applicationSummary?.contractPhase && (
          <Text className={classes.infoText}>
            {toHumanReadable(clikResult.applicationSummary.contractPhase)}
          </Text>
        ),
      },
      {
        label: "Request Date",
        content: clikResult?.applicationSummary?.requestDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.applicationSummary.requestDate)}
          </Text>
        ),
      },
      {
        label: "Application Amount",
        content: clikResult?.applicationSummary?.amount && (
          <Text className={classes.infoText}>
            {toCurrencyPrice(clikResult.applicationSummary.amount)}
          </Text>
        ),
      },
      {
        label: "Due Date",
        content: clikResult?.applicationSummary?.dueDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.applicationSummary.dueDate)}
          </Text>
        ),
      },
      {
        label: "Role",
        content: clikResult?.applicationSummary?.role && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.role}
          </Text>
        ),
      },
      {
        label: "Company Role",
        content: clikResult?.applicationSummary?.companyRole && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.companyRole}
          </Text>
        ),
      },
      {
        label: "Account Number",
        content: clikResult?.applicationSummary?.accountNumber && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.accountNumber}
          </Text>
        ),
      },
      {
        label: "Original Agreement Number",
        content: clikResult?.applicationSummary?.originalAgreementNumber && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.originalAgreementNumber}
          </Text>
        ),
      },
      {
        label: "Original Agreement Date",
        content: clikResult?.applicationSummary?.originalAgreementDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.applicationSummary.originalAgreementDate)}
          </Text>
        ),
      },
      {
        label: "Currency",
        content: clikResult?.applicationSummary?.currency && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.currency}
          </Text>
        ),
      },
    ];
  }, [classes, clikResult]);

  return { subject, establishmentData, applicationSummary };
};
