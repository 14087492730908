import { pickBy } from "lodash";
import isEqual from "lodash/isEqual";

import {
  DpdBucketFilterOptions,
  InvoiceFilter,
  PaymentStatusFilterOptions,
} from "../../graphql/generated.ts";
import {
  FiltersValue,
  IsRestructured,
} from "./ActivityManagementToolbar/types.ts";

const getInvoiceRestructuredApiFilter = (value: Array<string> | undefined) => {
  if (!value?.length) {
    return null;
  }
  if (isEqual(value, [IsRestructured.yes])) {
    return { equals: true };
  }
  if (isEqual(value, [IsRestructured.no])) {
    return { equals: false };
  }
  return null;
};

export const getInvoiceApiFilter = (value: FiltersValue): InvoiceFilter => {
  return pickBy({
    dpdBucket: value.dpdBucket?.length
      ? { in: value.dpdBucket as DpdBucketFilterOptions[] }
      : null,
    customerId: value.companyName?.length
      ? { in: value.companyName?.map((c) => Number(c)) }
      : null,
    paymentStatus: value.paymentStatus?.length
      ? { in: value.paymentStatus as PaymentStatusFilterOptions[] }
      : null,
    isRestructured: getInvoiceRestructuredApiFilter(value.isRestructured),
    collectorAssignedId: value.collector?.length
      ? { in: value.collector?.map((c) => Number(c)) }
      : null,
  });
};
