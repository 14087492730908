import { Button, Flex, Modal, Select, TextInput } from "@mantine/core";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import { InviteUserInput } from "../../../graphql/generated.ts";
import { userRoles } from "../constants.ts";

interface Props {
  opened?: boolean;
  onClose: () => void;
  onSubmit: (value: InviteUserInput) => void;
}

const InviteUserModal = ({ opened = false, onClose, onSubmit }: Props) => {
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      email: "",
      role: "",
    },
    validate: {
      email: isEmail("Invalid email"),
      role: isNotEmpty("Role is required"),
    },
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(form.values as InviteUserInput);
    handleClose();
  }, [form.values, handleClose, onSubmit]);

  return (
    <>
      <Modal
        opened={opened}
        title="Add a user"
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Flex gap={12} direction="column">
            <TextInput
              required
              type="email"
              label="Email"
              placeholder="Enter Email"
              size="m"
              {...form.getInputProps("email")}
            />
            <Select
              withinPortal
              dropdownPosition="bottom"
              required
              data={userRoles}
              label="Role"
              placeholder="Select Role"
              size="m"
              {...form.getInputProps("role")}
            />
          </Flex>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              add
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default InviteUserModal;
