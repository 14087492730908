import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikContractCollateral } from "../../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../../utils/date.ts";
import {
  toCurrencyPrice,
  toLocalNumber,
} from "../../../../../../../utils/number.ts";
import { useStyles } from "../../../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikContractCollateral;
}

interface CRCollateral {
  grantedCollateral: MultiFieldCardItem[];
  mainCollateralValue: MultiFieldCardItem[];
}

export const useCRCollateral = ({ data }: Props): CRCollateral => {
  const { classes } = useStyles();

  const grantedCollateral = useMemo(() => {
    return [
      {
        label: "Collateral Number",
        content: data?.collateralNumber && (
          <Text className={classes.infoText}>{data.collateralNumber}</Text>
        ),
      },
      {
        label: "Collateral Status",
        content: data?.collateralStatus && (
          <Text className={classes.infoText}>{data.collateralStatus}</Text>
        ),
      },
      {
        label: "Rating",
        content: data?.collateralRating && (
          <Text className={classes.infoText}>{data.collateralRating}</Text>
        ),
      },
      {
        label: "Rating Institution",
        content: data?.ratingInstitution && (
          <Text className={classes.infoText}>{data.ratingInstitution}</Text>
        ),
      },
      {
        label: "Bind Type",
        content: data?.bindType && (
          <Text className={classes.infoText}>{data.bindType}</Text>
        ),
      },
      {
        label: "Bind Date",
        content: data?.bindDate && (
          <Text className={classes.infoText}>{toLocalDate(data.bindDate)}</Text>
        ),
      },
      {
        label: "Collateral Owner Name",
        content: data?.collateralOwnerName && (
          <Text className={classes.infoText}>{data.collateralOwnerName}</Text>
        ),
      },

      {
        label: "Proof of Ownership",
        content: data?.proofOfOwnership && (
          <Text className={classes.infoText}>{data.proofOfOwnership}</Text>
        ),
      },
      {
        label: "Common Collateral Status",
        content: data?.commonCollateralStatus && (
          <Text className={classes.infoText}>
            {data.commonCollateralStatus}
          </Text>
        ),
      },
      {
        label: "Common Collateral Percentage",
        content: data?.commonCollateralPercentage && (
          <Text className={classes.infoText}>
            {toLocalNumber(data.commonCollateralPercentage)}%
          </Text>
        ),
      },
      {
        label: "Joint Account Credit Status",
        content: data?.jointAccountCreditStatus && (
          <Text className={classes.infoText}>
            {data.jointAccountCreditStatus}
          </Text>
        ),
      },
      {
        label: "Insured",
        content: data?.insured && (
          <Text className={classes.infoText}>{data.insured}</Text>
        ),
      },
      {
        label: "Information",
        content: data?.information && (
          <Text className={classes.infoText}>{data.information}</Text>
        ),
      },
    ];
  }, [data, classes]);

  const mainCollateralValue = useMemo(() => {
    return [
      {
        label: "Collateral Value NJO",
        content: data?.collateralValueNJOP && (
          <Text className={classes.infoText}>
            {toCurrencyPrice(data.collateralValueNJOP)}
          </Text>
        ),
      },
      {
        label: "Collateral Value Reporter",
        content: data?.collateralValueReporter && (
          <Text className={classes.infoText}>
            {toCurrencyPrice(data.collateralValueReporter)}
          </Text>
        ),
      },
      {
        label: "Assessment Date Reporter",
        content: data?.collateralAssessmentDateReporter && (
          <Text className={classes.infoText}>
            {toLocalDate(data.collateralAssessmentDateReporter)}
          </Text>
        ),
      },
      {
        label: "Collateral Value Indipendent Assersors",
        content: data?.collateralValueIndependentAssessors && (
          <Text className={classes.infoText}>
            {data.collateralValueIndependentAssessors}
          </Text>
        ),
      },
      {
        label: "Independent Assessors Name",
        content: data?.independentAssersorsName && (
          <Text className={classes.infoText}>
            {data.independentAssersorsName}
          </Text>
        ),
      },
      {
        label: "Assessment Date Indipendent Assersors",
        content: data?.assessmentDateIndependentAssersors && (
          <Text className={classes.infoText}>
            {data.assessmentDateIndependentAssersors}
          </Text>
        ),
      },
      {
        label: "Provider Type",
        content: data?.providerTypeCode && (
          <Text className={classes.infoText}>{data.providerTypeCode}</Text>
        ),
      },
      {
        label: "Provider",
        content: data?.providerCode && (
          <Text className={classes.infoText}>{data.providerCode}</Text>
        ),
      },
      {
        label: "Last Update Date",
        content: data?.referenceDate && (
          <Text className={classes.infoText}>
            {toLocalDate(data.referenceDate)}
          </Text>
        ),
      },
    ];
  }, [data, classes]);

  return { grantedCollateral, mainCollateralValue };
};
