import { Divider, Flex, Navbar, NavLink } from "@mantine/core";
import { useState } from "react";

import appConfig from "../../../config.ts";
import SvgLogout from "../../Icons/Logout.tsx";
import { navigationLinks } from "../constants.tsx";
import { useNavigationLinks } from "../hooks/useNavigationLinks.tsx";
import { useStyles } from "./Sidebar.styles.ts";

const Sidebar = () => {
  const [navBarIsOpen, setNavBarIsOpen] = useState(false);

  const { classes } = useStyles({ isOpen: navBarIsOpen });

  const renderedLinks = useNavigationLinks({
    links: navigationLinks,
    isOpen: navBarIsOpen,
  });

  return (
    <Navbar
      className={classes.wrapper}
      width={{ base: 84 }}
      hidden
      hiddenBreakpoint="sm"
      onMouseOver={() => setNavBarIsOpen(true)}
      onMouseOut={() => setNavBarIsOpen(false)}
    >
      <Flex
        direction="column"
        justify="space-between"
        className={classes.itemsWrapper}
      >
        <Flex direction="column">{renderedLinks}</Flex>
        <Flex direction="column" gap={12}>
          <Divider className={classes.divider} />
          <a
            className={classes.logoutWrapper}
            href={`${appConfig.apiUrl}/logout`}
          >
            <NavLink icon={<SvgLogout />} label="Log out" />
          </a>
        </Flex>
      </Flex>
    </Navbar>
  );
};

export default Sidebar;
