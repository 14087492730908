import { useQuery } from "urql";

import { GetPaymentHistoryDocument } from "../../../graphql/generated.ts";

interface Props {
  invoiceId: number | string;
}

export const usePaymentHistory = ({ invoiceId }: Props) => {
  return useQuery({
    query: GetPaymentHistoryDocument,
    variables: { invoiceId: Number(invoiceId) },
  });
};
