import { useQuery } from "urql";

import { DashboardOutstandingPrincipalAccumulativeDocument } from "../../../graphql/generated.ts";

interface Props {
  salesTeamName?: string[] | null;
}

export const useDashboardOutstandingPrincipalAccumulative = ({
  salesTeamName,
}: Props) =>
  useQuery({
    query: DashboardOutstandingPrincipalAccumulativeDocument,
    variables: { salesTeamName: salesTeamName },
  });
