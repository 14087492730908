import { AppRoute } from "../../constants";
import { RolePermission } from "../../graphql/generated.ts";
import { User } from "../../types/user.ts";
import { hasPermission } from "../../utils/user.ts";
import SvgCreditCard from "../Icons/CreditCard.tsx";
import SvgFolder from "../Icons/Folder.tsx";
import SvgSettings from "../Icons/Settings.tsx";

export const navigationLinks = [
  {
    label: "Setting",
    icon: <SvgSettings />,
    nestedLinks: [
      {
        label: "User Access",
        url: AppRoute.UserAccessSetting,
      },
    ],
  },
  {
    label: "Collection",
    icon: <SvgFolder />,
    nestedLinks: [
      {
        label: "Collection Dashboard",
        url: AppRoute.CollectionDashboard,
      },
      {
        label: "Activity Management",
        url: AppRoute.ActivityManagement,
      },
      {
        label: "Review & Approval",
        url: AppRoute.ReviewAndApproval,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewReviewApproval),
      },
    ],
  },
  {
    label: "Credit Limit",
    icon: <SvgCreditCard />,
    nestedLinks: [
      {
        label: "Limit Request",
        url: AppRoute.LimitRequest,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewLimitRequest),
      },
      {
        label: "CLIK Data",
        url: AppRoute.ClikData,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewClikData),
      },
    ],
    show: (user: User | null | undefined) => {
      const canViewLimitRequest = hasPermission(
        user,
        RolePermission.CanViewLimitRequest
      );
      const canViewClikData = hasPermission(
        user,
        RolePermission.CanViewClikData
      );
      return canViewLimitRequest || canViewClikData;
    },
  },
];
