import { Text } from "@mantine/core";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikSubjectAdditionalInfo } from "../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikSubjectAdditionalInfo | undefined | null;
}

export const useCRAdditionalInformation = ({
  data,
}: Props): MultiFieldCardItem[] | null => {
  const { classes } = useStyles();

  if (!data) {
    return null;
  }

  return [
    {
      label: "Additional Information No",
      content: data.additionalInformationNo && (
        <Text className={classes.infoText}>{data.additionalInformationNo}</Text>
      ),
    },
    {
      label: "Provider Type",
      content: data.providerType && (
        <Text className={classes.infoText}>{data.providerType}</Text>
      ),
    },
    {
      label: "Provider",
      content: data.providerCode && (
        <Text className={classes.infoText}>{data.providerCode}</Text>
      ),
    },
    {
      label: "Information Type",
      content: data.additionalInformationTypeDesc && (
        <Text className={classes.infoText}>
          {data.additionalInformationTypeDesc}
        </Text>
      ),
    },
    {
      label: "Details",
      content: data.additionalInformationValue && (
        <Text className={classes.infoText}>
          {data.additionalInformationValue}
        </Text>
      ),
    },
    {
      label: "Status",
      content: data.status && (
        <Text className={classes.infoText}>{data.status}</Text>
      ),
    },
    {
      label: "Status Date",
      content: data.statusDate && (
        <Text className={classes.infoText}>{toLocalDate(data.statusDate)}</Text>
      ),
    },
    {
      label: "Last Reference Date",
      content: data.referenceDate && (
        <Text className={classes.infoText}>
          {toLocalDate(data.referenceDate)}
        </Text>
      ),
    },
  ];
};
