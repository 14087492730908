import { dpdBucketAccumulativeOptions, dpdBucketOptions } from "../constants";
import { DpdBucket } from "../graphql/generated.ts";

export function getDpdBucketDisplayName(bucket: DpdBucket | null | undefined) {
  if (!bucket) {
    return null;
  }
  const values = Object.fromEntries(
    dpdBucketOptions.map((item) => [item.value, item.label])
  );
  return values[bucket];
}

export function getDpdBucketAccumulativeDisplayName() {
  return Object.fromEntries(
    dpdBucketAccumulativeOptions.map((item) => [item.value, item.label])
  );
}
