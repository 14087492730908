import { TabProps } from "@mantine/core";

interface Tab extends TabProps {
  label: string;
}

export const tabs: Tab[] = [
  {
    value: "companyInfo",
    label: "Company Info",
  },
  {
    value: "pengurusInfo",
    label: "Pengurus Info",
  },
  {
    value: "clikResult",
    label: "CLIK Result",
  },
];
