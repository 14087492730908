import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  companyInfoWrapper: {
    "@media (max-width: 48em)": {
      flexDirection: "column",
      gap: rem(12),
    },
  },
  companyInfoText: {
    fontSize: rem(14),
    fontWeight: 500,
    lineHeight: 1.3,
    wordBreak: "break-word",
    maxWidth: "70%",
    textAlign: "end",
  },
  companyInfoColorText: {
    color: theme.colors.midnightBlue[0],
  },
}));
