import { Flex } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import TableWaive from "../../../components/TableWaive/TableWaive";
import { getWaiveApiFilter } from "../../../components/TableWaive/utils";
import { RolePermission } from "../../../graphql/generated.ts";
import { hasPermission } from "../../../utils/user.ts";

export default function WaivesTab() {
  const { customerId } = useParams();

  const { user } = useCurrentUserContext();

  const canAdd = hasPermission(user, RolePermission.CanAddWaive);

  const filter = getWaiveApiFilter({ customerId });

  return (
    <Flex direction="column" gap={{ base: 16, sm: 24 }}>
      <TableWaive canAdd={canAdd} filter={filter} />
    </Flex>
  );
}
