import { Select, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import { Invoice } from "../../../../types/invoice.ts";
import { Collector } from "../../../../types/user.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { getDpdBucketDisplayName } from "../../../../utils/invoice.ts";
import { toLocalNumber } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../utils/user.ts";
import { TableData, TableRow } from "../../../Table/types.ts";
import { useStyles } from "../InvoicesTables.styles.ts";
import { getCollectorOptions } from "../utils.ts";

interface Props {
  data: Invoice[] | null | undefined;
  collectors?: Collector[] | null | undefined;
  canEditCollectorAssigned?: boolean;
  onChange: (id: number, value: string) => void;
}

export function useInvoicesTableData({
  data,
  collectors,
  canEditCollectorAssigned = false,
  onChange,
}: Props): TableData {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "invoiceNumber",
      title: "Inv No",
      sortable: true,
      width: "250px",
    },
    {
      accessor: "customerName",
      title: "Company Name",
      sortable: true,
      width: "350px",
    },
    {
      accessor: "totalInvoice",
      title: "Total Inv Amount",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "invoiceDueDate",
      title: "Inv Due Date",
      sortable: true,
      width: "150px",
    },
    { accessor: "dpd", title: "DPD", sortable: true, width: "100px" },
    {
      accessor: "dpdBucket",
      title: "DPD Bucket",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "totalPaid",
      title: "Total Paid Amount",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "totalAmountDue",
      title: "OSP Amount",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "lateCharge",
      title: "Total Late Charge",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "totalAccountReceivable",
      title: "Total AR",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "lastPaymentDate",
      title: "Last Payment Date",
      width: "150px",
    },
    {
      accessor: "lastPaymentAmount",
      title: "Last Paid Amount",
      width: "125px",
    },
    {
      accessor: "paymentStatus",
      title: "Payment Status",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "limitUsed",
      title: "Limit Used",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "hasException",
      title: "Has Exception",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "salesTeamName",
      title: "Sales Team",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "collectorAssigned",
      title: "Collector Assigned",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "isRestructured",
      title: "Is Restructured",
      width: "150px",
      sortable: true,
    },
  ];

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    //  stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();
  };

  const handleOnChange = useCallback(
    (id: number, value: string) => {
      onChange(id, value);
    },
    [onChange]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        invoiceNumber: item.invoiceNumber,
        customerName: item.customer.name,
        totalInvoice: toLocalNumber(item.totalInvoice),
        invoiceDueDate: toLocalDate(item.invoiceDueDate),
        dpd: toLocalNumber(item.dpd),
        dpdBucket: getDpdBucketDisplayName(item.dpdBucket),
        totalPaid: toLocalNumber(item.totalPaid),
        totalAmountDue: toLocalNumber(item.totalAmountDue),
        lateCharge: toLocalNumber(item.lateCharge),
        totalAccountReceivable: toLocalNumber(item.totalAccountReceivable),
        lastPaymentDate: toLocalDate(item.lastPayment?.paidAt),
        lastPaymentAmount: toLocalNumber(item.lastPayment?.amount),
        paymentStatus: toHumanReadable(item.paymentStatus),
        limitUsed: item.limitUsed,
        hasException: item.hasException ? "Yes" : "No",
        salesName: item.salesName,
        salesTeamName: item.salesTeamName,
        isRestructured: item.isRestructured ? "Yes" : "No",
        collectorAssigned: canEditCollectorAssigned ? (
          <Select
            key={item.id}
            data={getCollectorOptions(collectors)}
            value={
              item.collectorAssigned ? String(item.collectorAssigned.id) : null
            }
            placeholder="Select a collector"
            size="s"
            withinPortal
            zIndex={100}
            onChange={(value: string) => handleOnChange(item.id, value)}
            onClick={(event) => handleOnClick(event)}
          />
        ) : (
          <Text className={classes.collectorAssignedWrapper}>
            {getUserDisplayName(item.collectorAssigned)}
          </Text>
        ),
      };
    });
  }, [data, collectors, canEditCollectorAssigned, classes, handleOnChange]);

  return [columns, rows];
}
