import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import {
  ClikContractMaximum,
  ContractType,
} from "../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";
import { useStyles } from "../../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikContractMaximum | null | undefined;
  type: ContractType;
}

export const useCRMaximumDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  const { classes } = useStyles();

  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: "Max Overdue Payments Amount",
          content: data?.maxOverduePaymentsAmount && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.maxOverduePaymentsAmount)}
            </Text>
          ),
        },
        {
          label: "Max Overdue Payments Number",
          content: data?.maxOverduePaymentsNumber && (
            <Text className={classes.infoText}>
              {data.maxOverduePaymentsNumber}
            </Text>
          ),
        },
        {
          label: "Max Overdue Payments Number Date",
          content: data?.maxOverduePaymentsNumberDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data?.maxOverduePaymentsNumberDate)}
            </Text>
          ),
        },
        {
          label: "Max Days Past Due",
          content: data?.maxDaysPastDue && (
            <Text className={classes.infoText}>{data.maxDaysPastDue}</Text>
          ),
        },
        {
          label: "Max Days Past Due Date",
          content: data?.maxDaysPastDueDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data?.maxDaysPastDueDate)}
            </Text>
          ),
        },
        {
          label: "Worst Status",
          content: data?.worstStatus && (
            <Text className={classes.infoText}>{data.worstStatus}</Text>
          ),
        },
        {
          label: "Worst Status Date",
          content: data?.worstStatusDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.worstStatusDate)}
            </Text>
          ),
        },
      ];
    }

    if (type === ContractType.Bond || type === ContractType.OtherFacilities) {
      return [
        {
          label: "Max Overdue Payments Amount",
          content: data?.maxOverduePaymentsAmount && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.maxOverduePaymentsAmount)}
            </Text>
          ),
        },
        {
          label: "Max Days Past Due",
          content: data?.maxDaysPastDue && (
            <Text className={classes.infoText}>{data.maxDaysPastDue}</Text>
          ),
        },
        {
          label: "Max Days Past Due Date",
          content: null,
        },
        {
          label: "Worst Status",
          content: data?.worstStatus && (
            <Text className={classes.infoText}>{data.worstStatus}</Text>
          ),
        },
        {
          label: "Worst Status Date",
          content: data?.worstStatusDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.worstStatusDate)}
            </Text>
          ),
        },
      ];
    }

    if (
      type === ContractType.Guarantee ||
      type === ContractType.LetterOfCredit
    ) {
      return [
        {
          label: "Worst Status",
          content: data?.worstStatus && (
            <Text className={classes.infoText}>{data.worstStatus}</Text>
          ),
        },
        {
          label: "Worst Status Date",
          content: data?.worstStatusDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.worstStatusDate)}
            </Text>
          ),
        },
      ];
    }

    return [];
  }, [data, classes, type]);
};
