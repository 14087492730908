import { PaymentStatus, PaymentType } from "../../../graphql/generated.ts";
import { toHumanReadable } from "../../../utils/string.ts";

export const paymentTypeData = [
  {
    value: PaymentType.Top,
    label: "Term of Payment (TOP)",
  },
  {
    value: PaymentType.Cod,
    label: "Cash on Delivery (COD)",
  },
  {
    value: PaymentType.Cbd,
    label: "Cash Before Delivery (CBD)",
  },
];

export const paymentStatusData = [
  {
    value: PaymentStatus.Paid,
    label: toHumanReadable(PaymentStatus.Paid),
  },
  {
    value: PaymentStatus.NotPaid,
    label: toHumanReadable(PaymentStatus.NotPaid),
  },
  {
    value: PaymentStatus.Partial,
    label: toHumanReadable(PaymentStatus.Partial),
  },
];
