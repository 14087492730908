import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ContractType } from "../../../../../../graphql/generated.ts";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import {
  toCurrencyPrice,
  toLocalNumber,
} from "../../../../../../utils/number.ts";
import { useStyles } from "../../../ClikResultDetails.styles.tsx";
import { getCRContractListing } from "../../../utils.ts";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
  type: ContractType;
}

export const useCRGrantedDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  const { classes } = useStyles();

  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: "Characteristic Credit",
          content: data?.codeCharacteristicCredit && (
            <Text className={classes.infoText}>
              {data.codeCharacteristicCredit}
            </Text>
          ),
        },
        {
          label: "Agreement Credit",
          content: data?.codeAgreementCredit && (
            <Text className={classes.infoText}>{data.codeAgreementCredit}</Text>
          ),
        },
        {
          label: "Original Agreement Number",
          content: data?.originalAgreementNumber && (
            <Text className={classes.infoText}>
              {data.originalAgreementNumber}
            </Text>
          ),
        },
        {
          label: "Original Agreement Date",
          content: data?.originalAgreementDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.originalAgreementDate)}
            </Text>
          ),
        },
        {
          label: "Final Agreement Number",
          content: data?.finalAgreementNumber && (
            <Text className={classes.infoText}>
              {data.finalAgreementNumber}
            </Text>
          ),
        },
        {
          label: "Final Agreement Date",
          content: data?.finalAgreementDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.finalAgreementDate)}
            </Text>
          ),
        },
        {
          label: "Credit Frequency",
          content: data?.frequencyOfCredit && (
            <Text className={classes.infoText}>{data.frequencyOfCredit}</Text>
          ),
        },
        {
          label: "Credit Start Date",
          content: data?.startDateOfCredit && (
            <Text className={classes.infoText}>
              {toLocalDate(data.startDateOfCredit)}
            </Text>
          ),
        },
        {
          label: "Debtor Category",
          content: data?.debtorCategory && (
            <Text className={classes.infoText}>{data.debtorCategory}</Text>
          ),
        },
        {
          label: "Usage Type",
          content: data?.usageType && (
            <Text className={classes.infoText}>{data.usageType}</Text>
          ),
        },
        {
          label: "Usage Orientation",
          content: data?.usageOrientation && (
            <Text className={classes.infoText}>{data.usageOrientation}</Text>
          ),
        },
        {
          label: "Economic Sector",
          content: data?.economicSector && (
            <Text className={classes.infoText}>{data.economicSector}</Text>
          ),
        },
        {
          label: "Project Value",
          content: data?.projectValue && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.projectValue)}
            </Text>
          ),
        },
        {
          label: "Type of Interest Rate",
          content: data?.typeOfInterestRate && (
            <Text className={classes.infoText}>{data.typeOfInterestRate}</Text>
          ),
        },
        {
          label: "Interest Rate",
          content: data?.interestRate && (
            <Text className={classes.infoText}>
              {toLocalNumber(data.interestRate)}%
            </Text>
          ),
        },
        {
          label: "Financing Program",
          content: data?.financigProgram && (
            <Text className={classes.infoText}>{data.financigProgram}</Text>
          ),
        },
        {
          label: "City",
          content: data?.city && (
            <Text className={classes.infoText}>{data.city}</Text>
          ),
        },
      ];
    }

    if (type === ContractType.Bond) {
      return [
        {
          label: "Sovereign Rating",
          content: data?.sovereignRate && (
            <Text className={classes.infoText}>{data.sovereignRate}</Text>
          ),
        },
        {
          label: "Listing",
          content: data?.listing && (
            <Text className={classes.infoText}>
              {getCRContractListing(data.listing)}
            </Text>
          ),
        },
        {
          label: "Bond Rating",
          content: data?.bondRating && (
            <Text className={classes.infoText}>{data.bondRating}</Text>
          ),
        },
        {
          label: "Ownership Purpose",
          content: data?.ownershipPurpose && (
            <Text className={classes.infoText}>{data.ownershipPurpose}</Text>
          ),
        },
        {
          label: "Purchase date",
          content: data?.purchaseDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.purchaseDate)}
            </Text>
          ),
        },
        {
          label: "Interest Rate",
          content: data?.interestRate && (
            <Text className={classes.infoText}>
              {toLocalNumber(data.interestRate)}%
            </Text>
          ),
        },
      ];
    }

    if (type === ContractType.LetterOfCredit) {
      return [
        {
          label: "Destination",
          content: data?.destination && (
            <Text className={classes.infoText}>{data.destination}</Text>
          ),
        },
        {
          label: "Original Agreement Number",
          content: data?.originalAgreementNumber && (
            <Text className={classes.infoText}>
              {data.originalAgreementNumber}
            </Text>
          ),
        },
        {
          label: "Original Agreement Date",
          content: data?.originalAgreementDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.originalAgreementDate)}
            </Text>
          ),
        },
        {
          label: "Final Agreement Number",
          content: data?.finalAgreementNumber && (
            <Text className={classes.infoText}>
              {data.finalAgreementNumber}
            </Text>
          ),
        },
        {
          label: "Final Agreement Date",
          content: data?.finalAgreementDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.finalAgreementDate)}
            </Text>
          ),
        },
        {
          label: "Bank Beneficiary",
          content: data?.bankBeneficiery && (
            <Text className={classes.infoText}>{data.bankBeneficiery}</Text>
          ),
        },
        {
          label: "Default Date",
          content: data?.defaultDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.defaultDate)}
            </Text>
          ),
        },
      ];
    }

    if (type === ContractType.Guarantee) {
      return [
        {
          label: "Guarantee Objective",
          content: data?.guaranteeObjective && (
            <Text className={classes.infoText}>{data.guaranteeObjective}</Text>
          ),
        },
        {
          label: "Original Agreement Number",
          content: data?.originalAgreementNumber && (
            <Text className={classes.infoText}>
              {data.originalAgreementNumber}
            </Text>
          ),
        },
        {
          label: "Original Agreement Date",
          content: data?.originalAgreementDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.originalAgreementDate)}
            </Text>
          ),
        },
        {
          label: "Final Agreement Number",
          content: data?.finalAgreementNumber && (
            <Text className={classes.infoText}>
              {data.finalAgreementNumber}
            </Text>
          ),
        },
        {
          label: "Final Agreement Date",
          content: data?.finalAgreementDate && (
            <Text className={classes.infoText}>
              {toLocalDate(data.finalAgreementDate)}
            </Text>
          ),
        },
        {
          label: "Guarantor Name",
          content: data?.guarantorName && (
            <Text className={classes.infoText}>{data.guarantorName}</Text>
          ),
        },
        {
          label: "Default Date",
          content: data?.defaultDate && (
            <Text className={classes.infoText}>{data.defaultDate}</Text>
          ),
        },
      ];
    }

    if (type === ContractType.OtherFacilities) {
      return [
        {
          label: "Interest Rate",
          content: data?.interestRate && (
            <Text className={classes.infoText}>
              {toLocalNumber(data.interestRate)}%
            </Text>
          ),
        },
      ];
    }
    return [];
  }, [data, classes, type]);
};
