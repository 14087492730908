import { ActionIcon, Flex } from "@mantine/core";
import { Tabs } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { isNull } from "lodash";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import SvgWarning from "../../components/Icons/Warning.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import { RolePermission } from "../../graphql/generated.ts";
import { useClikCities } from "../../hooks/api/clikData/useClikCities.ts";
import { useClikCountries } from "../../hooks/api/clikData/useClikCountries.ts";
import { useClikData } from "../../hooks/api/clikData/useClikData.ts";
import { useCompanies } from "../../hooks/api/customer/useCompanies.ts";
import { hasPermission } from "../../utils/user.ts";
import { useStyles } from "./ClikDataDetailsPage.styles.ts";
import ClikResultList from "./ClikResultList/ClikResultList.tsx";
import CompanyInfo from "./CompanyInfo/CompanyInfo.tsx";
import { tabs } from "./constants.tsx";
import PengurusInfo from "./PengurusInfo/PengurusInfo.tsx";

interface Props {
  tabIds?: string[];
}

const ClikDataDetailsPage = ({
  tabIds = ["companyInfo", "pengurusInfo", "clikResult"],
}: Props) => {
  const { classes } = useStyles();

  const { user: currentUser } = useCurrentUserContext();

  const navigate = useNavigate();

  const { id } = useParams() as { id: string };

  const [
    { data: clikDataResponse, fetching: clikDataFetching, error },
    refreshClikData,
  ] = useClikData({ id });

  const [{ data: clikCitiesData, fetching: clikCitiesFetching }] =
    useClikCities();

  const [{ data: clikCountriesData, fetching: clikCountriesFetching }] =
    useClikCountries();

  const [{ data: companiesData, fetching: companiesFetching }] = useCompanies();

  const clikData = clikDataResponse?.clikData;
  const limitRequest = clikData?.limitRequest;

  const isFetching = useMemo(() => {
    return (
      clikCitiesFetching ||
      clikCountriesFetching ||
      companiesFetching ||
      clikDataFetching
    );
  }, [
    clikCitiesFetching,
    companiesFetching,
    clikCountriesFetching,
    clikDataFetching,
  ]);

  const displayTabs = tabs.filter((tab) => tabIds?.includes(tab.value));

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditLimitRequest
  );

  const handleBack = () => navigate(-1);

  if (error || isNull(clikData)) {
    navigate(AppRoute.ClikData);
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Layout>
      <Preloader loading={isFetching} />
      <Flex direction="column" gap={{ base: 24, sm: 20 }}>
        <Flex gap={20}>
          <ActionIcon size="m" variant="outlineGolden" onClick={handleBack}>
            <SvgChevronLeft />
          </ActionIcon>
          <Title className={classes.titleWrapper}>
            {limitRequest?.company?.name
              ? `CLIK Data Details - ${limitRequest.company.name}`
              : "CLIK Data Details"}
          </Title>
        </Flex>
        <Tabs
          defaultValue={displayTabs?.[0]?.value}
          keepMounted={false}
          variant="outline"
        >
          <Tabs.List>
            {displayTabs.map((item, index) => {
              return (
                <Tabs.Tab key={index} value={item.value}>
                  {item.label}
                </Tabs.Tab>
              );
            })}
          </Tabs.List>
          <Tabs.Panel value={tabs[0].value}>
            <CompanyInfo
              canEdit={hasEditAccess}
              limitRequest={limitRequest}
              cities={clikCitiesData?.clikCities}
              countries={clikCountriesData?.clikCountries}
              companies={companiesData?.customers}
              isFetching={isFetching}
              onRefresh={refreshClikData}
            />
          </Tabs.Panel>
          <Tabs.Panel value={tabs[1].value}>
            <PengurusInfo
              canEdit={hasEditAccess}
              limitRequestId={limitRequest?.id}
              loading={isFetching}
              cities={clikCitiesData?.clikCities}
              countries={clikCountriesData?.clikCountries}
            />
          </Tabs.Panel>
          <Tabs.Panel value={tabs[2].value}>
            <ClikResultList clikDataId={clikData?.id} />
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </Layout>
  );
};

export default ClikDataDetailsPage;
