import { CombinedError } from "@urql/core";
import { DataTableSortStatus } from "mantine-datatable";

import { SortDirection } from "../components/Table/types.ts";
import { OrderBy } from "../graphql/generated.ts";
import { ApiOrderBy, ApiPagination } from "../types/api.ts";
import { TAKE } from "./pagination.ts";

export const getApiOrderBy = (sort: DataTableSortStatus): ApiOrderBy => {
  const direction = {
    [SortDirection.asc]: OrderBy.Asc,
    [SortDirection.desc]: OrderBy.Desc,
  };

  return { [sort.columnAccessor]: direction[sort.direction] };
};

export const getApiPaginationData = (
  page: number | null | undefined
): ApiPagination | undefined => {
  if (!page) {
    return undefined;
  }
  return { skip: (page - 1) * TAKE, take: TAKE };
};

export const formatApiMessage = (error: CombinedError): string => {
  return error.message.replace("[GraphQL] ", "");
};
