import { useQuery } from "urql";

import {
  CollectionActivityFilter,
  CollectionActivityOrderBy,
  GetCollectionActivitiesDocument,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api";
import { ApiHookProps } from "../types.ts";

export const useCollectionActivities = ({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<CollectionActivityFilter, CollectionActivityOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetCollectionActivitiesDocument, variables, pause });
};
