import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  infoText: {
    fontSize: rem(14),
    fontWeight: 500,
    lineHeight: 1.3,
    wordBreak: "break-word",
    maxWidth: "70%",
    textAlign: "end",
    color: theme.colors.midnightBlue[0],
    textDecoration: "none",
  },
  cardSubtitle: {
    color: theme.colors.grayIce[0],
    fontSize: rem(14),
    fontWeight: 600,
    lineHeight: 1.5,
    leadingTrim: "both",
  },
  contractBlockWrapper: {
    "@media (max-width: 74em)": {
      flexDirection: "column",
    },
  },
  contractBlockItem: {
    "@media (max-width: 74em)": {
      width: "100%",
    },
  },
  referenceDateWrapper: {
    textTransform: "capitalize",
  },
}));
