import { SelectItem } from "@mantine/core/lib/Select/types";
import get from "lodash/get";

import { PaymentStatus } from "../../../graphql/generated.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import { IsRestructured } from "./types.ts";

export const paymentStatusData: SelectItem[] = Object.keys(PaymentStatus).map(
  (key) => ({
    value: get(PaymentStatus, key),
    label: toHumanReadable(get(PaymentStatus, key)),
  })
);

export const isRestructuredData: SelectItem[] = Object.keys(IsRestructured).map(
  (key) => ({
    value: get(IsRestructured, key),
    label: toHumanReadable(get(IsRestructured, key)),
  })
);
