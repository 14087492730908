import { useQuery } from "urql";

import {
  ClikAccountReceivableFilter,
  ClikAccountReceivableOrderBy,
  GetClikAccountReceivablesDocument,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export function useClikAccountReceivables({
  filter,
  orderBy,
  page,
}: ApiHookProps<ClikAccountReceivableFilter, ClikAccountReceivableOrderBy>) {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetClikAccountReceivablesDocument, variables });
}
