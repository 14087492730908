import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetClikContractSummaryDocument } from "../../../graphql/generated.ts";

interface Props {
  clikResultId: number;
}

export function useClikContractSummary({ clikResultId }: Props) {
  return useQuery({
    query: GetClikContractSummaryDocument,
    variables: { clikResultId: Number(clikResultId) },
    pause: isNil(clikResultId),
  });
}
