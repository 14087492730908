import {
  Box,
  Card,
  Divider,
  Flex,
  MediaQuery,
  Skeleton,
  Text,
} from "@mantine/core";
import classNames from "classnames";
import React, { ReactNode, useCallback, useMemo } from "react";

import { ThemeGradientBackground } from "../../../utils/themeGradientBackground.ts";
import { CardEmptyValue } from "../constants.tsx";
import { CardContent } from "../types.ts";
import { isCardContentEmpty } from "../utils.tsx";
import { useStyles } from "./MultiFieldCard.styles.tsx";

export interface MultiFieldCardItem {
  label: string;
  content: CardContent;
  isColumn?: boolean;
}

export interface MultiFieldCardProps {
  label?: string;
  items: MultiFieldCardItem[];
  variant?: ThemeGradientBackground;
  loading?: boolean;
  cardControl?: ReactNode;
  customVariant?: ReactNode;
  cols?: 1 | 2;
}

export default function MultiFieldCard({
  items,
  label,
  variant = "golden",
  loading = false,
  cardControl,
  customVariant,
  cols = 1,
}: MultiFieldCardProps) {
  const { classes } = useStyles({ variant });

  const getItemNode = useCallback(
    (content: CardContent) => {
      if (isCardContentEmpty(content)) {
        return CardEmptyValue;
      }
      return React.isValidElement(content) ? (
        content
      ) : (
        <Text className={classes.itemContentText}>{content}</Text>
      );
    },
    [classes.itemContentText]
  );

  const renderCardColumn = useCallback(
    (items: MultiFieldCardItem[]) => (
      <Flex direction="column" w="100%" gap={3}>
        {items.map((item, index) => (
          <Flex
            key={index}
            justify="space-between"
            gap={item.isColumn ? 4 : 24}
            className={classNames(item.isColumn && classes.itemColumn)}
          >
            <Text className={classes.itemLabel}>{item.label}</Text>
            {getItemNode(item.content)}
          </Flex>
        ))}
      </Flex>
    ),
    [classes.itemColumn, classes.itemLabel, getItemNode]
  );

  const cardContent = useMemo(() => {
    if (cols === 1) {
      return renderCardColumn(items);
    }
    const itemsCountPerCol = Math.ceil(items.length / 2);
    const renderedColumns = [
      renderCardColumn(items.slice(0, itemsCountPerCol)),
      renderCardColumn(items.slice(itemsCountPerCol)),
    ];
    return (
      <Flex gap={30} w="100%" className={classes.colsWrapper}>
        {renderedColumns[0]}
        <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
          <Divider orientation="horizontal" w={1} />
        </MediaQuery>
        {renderedColumns[1]}
      </Flex>
    );
  }, [cols, renderCardColumn, items, classes]);

  return (
    <Skeleton visible={loading}>
      <Card padding={20} radius={4} className={classes.wrapper}>
        <Flex direction="column" gap={12}>
          <Flex justify="space-between">
            {customVariant ? (
              customVariant
            ) : (
              <Flex gap={12} align="center">
                <Box className={classes.pin} />
                <Text className={classes.label}>{label}</Text>
              </Flex>
            )}
            {cardControl}
          </Flex>
          <Divider />
          {cardContent}
        </Flex>
      </Card>
    </Skeleton>
  );
}
