import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikCompanyShareholder } from "../../../../../graphql/generated.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";
import { getCRDisplayAddress } from "../../utils.ts";

interface Props {
  data: ClikCompanyShareholder[] | null | undefined;
}

export const useCRCompanyShareholderCardsInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  const { classes } = useStyles();

  return useMemo(() => {
    if (!data) {
      return null;
    }

    return data?.map((item, index) => ({
      label: `ShareHolder Data  ${index + 1}: ${item.name.toUpperCase()}`,
      variant: "bronze",
      items: [
        {
          label: "Gender",
          content: <Text className={classes.infoText}>{item.gender}</Text>,
        },
        {
          label: "Share of Ownership",
          content: <Text className={classes.infoText}>{item.share}</Text>,
        },
        {
          label: "Position",
          content: <Text className={classes.infoText}>{item.position}</Text>,
        },
        {
          label: "Mandate Status",
          content: (
            <Text className={classes.infoText}>{item.mandateStatus}</Text>
          ),
        },
        {
          label: "Identity Type",
          content: (
            <Text className={classes.infoText}>
              {item.indetification?.type}
            </Text>
          ),
        },
        {
          label: "Identity Number",
          content: (
            <Text className={classes.infoText}>
              {item.indetification?.number}
            </Text>
          ),
        },
        {
          label: "Address",
          content: (
            <Text className={classes.infoText}>
              {getCRDisplayAddress(item.address)}
            </Text>
          ),
        },
      ],
    }));
  }, [data, classes]);
};
