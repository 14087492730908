import { Spoiler as MantineSpoiler, Text } from "@mantine/core";
import { useState } from "react";

import { useStyles } from "./Spoilers.styles.ts";

interface SpoilerProps {
  text: string;
}
const Spoiler = ({ text }: SpoilerProps) => {
  const maxStringLength = 17;

  const [open, setOpened] = useState(false);

  const { classes } = useStyles({ open });

  return (
    <>
      {text.length > maxStringLength ? (
        <MantineSpoiler
          maxHeight={21}
          hideLabel="Hide"
          showLabel="More"
          className={classes.wrapper}
          onClick={() => setOpened(!open)}
        >
          <Text
            className={classes.textWrapper}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </MantineSpoiler>
      ) : (
        text
      )}
    </>
  );
};

export default Spoiler;
