import { useQuery } from "urql";

import {
  ClikGrantedCreditFilter,
  ClikGrantedCreditOrderBy,
  GetClikGrantedCreditsDocument,
} from "../../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../../utils/api.ts";
import { ApiHookProps } from "../../types.ts";

export const useClikGrantedCredits = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<ClikGrantedCreditFilter, ClikGrantedCreditOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetClikGrantedCreditsDocument, variables });
};
