import { Flex } from "@mantine/core";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import RestructureDetails from "../../../components/TableRestructure/RestructureDetails/RestructureDetails";
import TableRestructure from "../../../components/TableRestructure/TableRestructure";
import { getRestructureApiFilter } from "../../../components/TableRestructure/utils";
import { RolePermission } from "../../../graphql/generated.ts";
import { Restructure } from "../../../types/restructure";
import { hasPermission } from "../../../utils/user.ts";

export default function RestructuresTab() {
  const { customerId } = useParams();

  const [selectedRow, setSelectedRow] = useState<Restructure | null>();

  const { user } = useCurrentUserContext();

  const canAddRestructureDetails = hasPermission(
    user,
    RolePermission.CanAddRestructureDetails
  );

  const canEditRestructureDetails = hasPermission(
    user,
    RolePermission.CanEditRestructureDetails
  );

  const canAddRestructure = hasPermission(
    user,
    RolePermission.CanAddRestructure
  );

  const canEditRestructure = hasPermission(
    user,
    RolePermission.CanEditRestructure
  );

  const filter = getRestructureApiFilter({ customerId });

  return (
    <Flex direction="column" gap={{ base: 16, sm: 24 }}>
      {selectedRow ? (
        <RestructureDetails
          restructure={selectedRow}
          canAdd={canAddRestructureDetails}
          canEdit={canEditRestructureDetails}
          onBreadcrumbsClick={() => setSelectedRow(null)}
        />
      ) : (
        <TableRestructure
          withToolbar
          filter={filter}
          canAdd={canAddRestructure}
          canEdit={canEditRestructure}
          onSelectRow={setSelectedRow}
        />
      )}
    </Flex>
  );
}
