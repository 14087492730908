import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikResult } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";

interface Props {
  clikResult: ClikResult;
}

export interface CRIndividualInfo {
  subject: MultiFieldCardItem[];
  indentificationDocuments: MultiFieldCardItem[];
  applicationSummary: MultiFieldCardItem[];
}

export const useCRIndividualResultInfoCards = ({
  clikResult,
}: Props): CRIndividualInfo => {
  const { classes } = useStyles();

  const subject = useMemo(() => {
    return [
      {
        label: "CB Subject Code",
        content: clikResult?.individualSubject?.cbSubjectCode && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.cbSubjectCode}
          </Text>
        ),
      },
      {
        label: "Provider Subject No",
        content: clikResult?.individualSubject?.providerSubjectNumber && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.providerSubjectNumber}
          </Text>
        ),
      },
      {
        label: "Last Update Date",
        content: clikResult?.individualSubject?.lastUpdatedDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.individualSubject.lastUpdatedDate)}
          </Text>
        ),
      },
      {
        label: "Resident",
        content: clikResult?.individualSubject?.resident && (
          <Text className={classes.infoText}>
            {toHumanReadable(clikResult.individualSubject.resident)}
          </Text>
        ),
      },
      {
        label: "Name As Id",
        content: clikResult?.individualSubject?.nameAsId && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.nameAsId}
          </Text>
        ),
      },
      {
        label: "Full Name",
        content: clikResult?.individualSubject?.fullName && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.fullName}
          </Text>
        ),
      },
      {
        label: "Mother's Name",
        content: clikResult?.individualSubject?.mothersName && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.mothersName}
          </Text>
        ),
      },
      {
        label: "Gender",
        content: clikResult?.individualSubject?.gender && (
          <Text className={classes.infoText}>
            {toHumanReadable(clikResult.individualSubject.gender)}
          </Text>
        ),
      },
      {
        label: "Date of Birth",
        content: clikResult?.individualSubject?.dateOfBirth && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.individualSubject.dateOfBirth)}
          </Text>
        ),
      },
      {
        label: "Place of Birth",
        content: clikResult?.individualSubject?.placeOfBirth && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.placeOfBirth}
          </Text>
        ),
      },
      {
        label: "Marital Status",
        content: clikResult?.individualSubject?.maritalStatus && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.maritalStatus}
          </Text>
        ),
      },
      {
        label: "Educational Status",
        content: clikResult?.individualSubject?.educationStatus && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.educationStatus}
          </Text>
        ),
      },
    ];
  }, [classes, clikResult]);

  const indentificationDocuments = useMemo(() => {
    const documentsList =
      clikResult?.individualSubject?.identities.map((item) => ({
        label: item.type,
        content: <Text className={classes.infoText}>{item.number}</Text>,
      })) ?? [];

    return [
      ...documentsList,
      {
        label: "NPWP/Tax Registration Number",
        content: clikResult?.individualSubject?.npwpId && (
          <Text className={classes.infoText}>
            {clikResult.individualSubject.npwpId}
          </Text>
        ),
      },
    ];
  }, [classes, clikResult]);

  const applicationSummary = useMemo(() => {
    return [
      {
        label: "CB Contract Code",
        content: clikResult?.applicationSummary?.cbContractCode && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.cbContractCode}
          </Text>
        ),
      },
      {
        label: "Contract Type",
        content: clikResult?.applicationSummary?.contractType && (
          <Text className={classes.infoText}>
            {toHumanReadable(clikResult.applicationSummary.contractType)}
          </Text>
        ),
      },
      {
        label: "Contract Phase",
        content: clikResult?.applicationSummary?.contractPhase && (
          <Text className={classes.infoText}>
            {toHumanReadable(clikResult.applicationSummary.contractPhase)}
          </Text>
        ),
      },
      {
        label: "Request Date",
        content: clikResult?.applicationSummary?.requestDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.applicationSummary.requestDate)}
          </Text>
        ),
      },
      {
        label: "Application Amount",
        content: clikResult?.applicationSummary?.amount && (
          <Text className={classes.infoText}>
            {toCurrencyPrice(clikResult.applicationSummary.amount)}
          </Text>
        ),
      },
      {
        label: "Due Date",
        content: clikResult?.applicationSummary?.dueDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.applicationSummary.dueDate)}
          </Text>
        ),
      },
      {
        label: "Role",
        content: clikResult?.applicationSummary?.role && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.role}
          </Text>
        ),
      },
      {
        label: "Company Role",
        content: clikResult?.applicationSummary?.companyRole && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.companyRole}
          </Text>
        ),
      },
      {
        label: "Account Number",
        content: clikResult?.applicationSummary?.accountNumber && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.accountNumber}
          </Text>
        ),
      },
      {
        label: "Original Agreement Number",
        content: clikResult?.applicationSummary?.originalAgreementNumber && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.originalAgreementNumber}
          </Text>
        ),
      },
      {
        label: "Original Agreement Date",
        content: clikResult?.applicationSummary?.originalAgreementDate && (
          <Text className={classes.infoText}>
            {toLocalDate(clikResult.applicationSummary.originalAgreementDate)}
          </Text>
        ),
      },
      {
        label: "Currency",
        content: clikResult?.applicationSummary?.currency && (
          <Text className={classes.infoText}>
            {clikResult.applicationSummary.currency}
          </Text>
        ),
      },
    ];
  }, [classes, clikResult]);

  return { subject, indentificationDocuments, applicationSummary };
};
