import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUserContext } from "../components/Auth/CurrentUserProvider.tsx";
import { AppRoute } from "../constants.ts";
import { RolePermission } from "../graphql/generated.ts";
import { hasPermission } from "../utils/user.ts";

export const MainPage = () => {
  const navigate = useNavigate();

  const { user: currentUser } = useCurrentUserContext();
  const hasUserSettingAccess = hasPermission(
    currentUser,
    RolePermission.CanViewUserAccessSetting
  );

  useEffect(() => {
    hasUserSettingAccess
      ? navigate(AppRoute.UserAccessSetting)
      : navigate(AppRoute.ActivityManagement);
  }, [navigate, hasUserSettingAccess]);

  return null;
};
