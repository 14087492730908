import { pickBy } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";

import { ClikDataFilter } from "../../graphql/generated.ts";
import {
  ClikDataOrderBy,
  LimitRequestOrderBy,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";
import { FiltersValue } from "./types.ts";

export const geClikDataApiFilter = (value: FiltersValue): ClikDataFilter => {
  return pickBy({
    limitRequest: value?.companyName?.length
      ? {
          companyId: {
            in: value.companyName.map((c) => Number(c)),
          },
        }
      : null,
  });
};

export const getClikDataApiSort = (
  tableSort: DataTableSortStatus
): ClikDataOrderBy => {
  const { companyName, ...apiSort } = getApiOrderBy(tableSort);
  let limitRequest: LimitRequestOrderBy | undefined = undefined;
  if (companyName) {
    limitRequest = { company: { name: companyName } };
  }
  return {
    ...apiSort,
    limitRequest,
  };
};
