import isNil from "lodash/isNil";
import { useQuery } from "urql";

import {
  ClikAddressOrderBy,
  GetClikAddressesDocument,
} from "../../../graphql/generated.ts";

interface Props {
  clikResultId: number | null | undefined;
  orderBy: ClikAddressOrderBy;
}

export function useClikAddresses({ clikResultId, orderBy }: Props) {
  const variables = {
    filter: { clikResultId: { equals: clikResultId } },
    orderBy,
  };
  return useQuery({
    query: GetClikAddressesDocument,
    variables,
    pause: isNil(clikResultId),
  });
}
