import { useQuery } from "urql";

import { GetClikResultDocument } from "../../../graphql/generated.ts";

interface Props {
  id: number | string;
}

export function useClikResult({ id }: Props) {
  return useQuery({
    query: GetClikResultDocument,
    variables: { id: Number(id) },
  });
}
