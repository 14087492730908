import { ActionIcon, Flex } from "@mantine/core";
import { Tabs } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import SvgWarning from "../../components/Icons/Warning.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import { UpdateCustomerInput } from "../../graphql/generated.ts";
import { useCollectionSummary } from "../../hooks/api/collectionSummary/useCollectionSummary.ts";
import { useCustomer } from "../../hooks/api/customer/useCustomer.ts";
import { useUpdateCustomer } from "../../hooks/api/customer/useUpdateCustomer.ts";
import { useCollectors } from "../../hooks/api/user/useCollectors.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import ActivityManagementCards from "./ActivityManagementCards/ActivityManagementCards.tsx";
import { useStyles } from "./ActivityManagementDetail.styles.ts";
import { tabs } from "./constants.tsx";

interface Props {
  tabIds?: string[];
}

export default function ActivityManagementDetailPage({
  tabIds = [
    "odooContactData",
    "invoices",
    "collectionActivity",
    "collectionDocument",
    "restructure",
    "waive",
  ],
}: Props) {
  const { classes } = useStyles();

  const { customerId } = useParams() as { customerId: string };

  const navigate = useNavigate();

  const [
    { data: customerData, fetching: customerFetching, error },
    refreshCustomer,
  ] = useCustomer({
    id: customerId,
  });
  const customer = customerData?.customer;

  const [
    { data: summaryData, fetching: summaryFetching, error: summaryError },
    refreshCustomerSummary,
  ] = useCollectionSummary({ customerId });
  const collectionSummary = summaryData?.collectionSummary;

  const [{ data: collectorsData, fetching: collectorsFetching }] =
    useCollectors();
  const collectors = collectorsData?.users.data;

  const isFetching = useMemo(() => {
    return customerFetching || summaryFetching || collectorsFetching;
  }, [collectorsFetching, customerFetching, summaryFetching]);

  const [, updateCustomer] = useMutationNotificationWrapper(
    useUpdateCustomer(),
    {
      success: { message: "Customer has been updated." },
    }
  );

  const handleUpdateCustomer = useCallback(
    (value: Partial<UpdateCustomerInput>) => {
      if (!customer) {
        return;
      }
      updateCustomer({
        id: Number(customerId),
        input: {
          collectorAssignedId: value.collectorAssignedId
            ? Number(value.collectorAssignedId)
            : customer.collectorAssigned?.id,
          problemCategory: value.problemCategory ?? customer.problemCategory,
        },
      }).then((data) => {
        if (!data.error) {
          refreshCustomer();
          refreshCustomerSummary();
        }
      });
    },
    [
      customer,
      customerId,
      refreshCustomer,
      refreshCustomerSummary,
      updateCustomer,
    ]
  );

  const displayTabs = tabs.filter((tab) => tabIds?.includes(tab.value));

  const handleBack = () => navigate(-1);

  if (error || summaryError) {
    navigate(AppRoute.ActivityManagement);
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Layout>
      <Preloader loading={isFetching} />
      {customer && collectionSummary && collectors && (
        <Flex direction="column" gap={{ base: 24, sm: 20 }}>
          <Flex direction="column" gap={16}>
            <Flex gap={20}>
              <ActionIcon size="m" variant="outlineGolden" onClick={handleBack}>
                <SvgChevronLeft />
              </ActionIcon>
              <Title className={classes.titleWrapper}>
                {`${customer.name} - ${customer.partnerCode}`}
              </Title>
            </Flex>
            <ActivityManagementCards
              customer={customer}
              collectionSummary={collectionSummary}
              collectors={collectors}
              onChange={handleUpdateCustomer}
            />
          </Flex>
          <Tabs
            defaultValue={displayTabs?.[0]?.value}
            keepMounted={false}
            variant="outline"
          >
            <Tabs.List>
              {displayTabs.map((item, index) => {
                return (
                  <Tabs.Tab key={index} value={item.value}>
                    {item.label}
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>
            {displayTabs.map((item, index) => {
              return (
                <Tabs.Panel key={index} value={item.value}>
                  {item.children}
                </Tabs.Panel>
              );
            })}
          </Tabs>
        </Flex>
      )}
    </Layout>
  );
}
