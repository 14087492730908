import { Breadcrumbs, Flex, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SvgWarning from "../../../components/Icons/Warning.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection } from "../../../components/Table/types.ts";
import {
  ClikDataOrderBy,
  OrderBy,
  UpdateClikResultInput,
} from "../../../graphql/generated.ts";
import { useClikResults } from "../../../hooks/api/clikResult/useClikResults.ts";
import { useUpdateClikResult } from "../../../hooks/api/clikResult/useUpdateClikResult.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { Breadcrumb } from "../../../types/breadcrumb.ts";
import { ClikResult } from "../../../types/clikResult.ts";
import { getApiOrderBy } from "../../../utils/api.ts";
import { getPagesCount } from "../../../utils/pagination.ts";
import ClikResultDetails from "../ClikResultDetails/ClikResultDetails.tsx";
import EditClikResultModal from "./EditClikResultModal/EditClikResultModal.tsx";
import { useClikResultTableData } from "./hooks/useClikResultTableData.tsx";

interface ClikResultProps {
  clikDataId: number | null | undefined;
}

const ClikResultList = ({ clikDataId }: ClikResultProps) => {
  const [orderBy, setOrderBy] = useState<ClikDataOrderBy>({
    createdAt: OrderBy.Asc,
  });
  const [page, setPage] = useState<number>(1);
  const [clikResultToEdit, setClikResultToEdit] = useState<{
    id: number;
    data: ClikResult;
  } | null>(null);

  const [selectedClikResultId, setSelectedClikResultId] = useState<
    number | null
  >(null);

  const [
    clikDataEditModalOpened,
    { open: clikDataEditModalOpen, close: clikDataEditModalClose },
  ] = useDisclosure(false);

  const [{ data, fetching }, refreshData] = useClikResults({
    filter: {
      clikData: { id: { equals: clikDataId } },
    },
    orderBy,
    page,
  });

  const [, updateClikResult] = useMutationNotificationWrapper(
    useUpdateClikResult(),
    {
      success: { message: "Clik Data has been updated." },
    }
  );

  const clikResultData: ClikResult[] | undefined | null =
    data?.clikResults?.data;

  const pageCount = getPagesCount(data?.clikResults?.total);

  const handleOpenEditModal = (id: number) => {
    const item = clikResultData?.find((item) => item.id === id);
    if (!item) {
      return;
    }
    setClikResultToEdit({ id, data: item });
    clikDataEditModalOpen();
  };

  const [columns, rows] = useClikResultTableData({
    data: clikResultData,
    onEdit: handleOpenEditModal,
    onOpenDetails: setSelectedClikResultId,
  });

  const breadcrumbs: Breadcrumb[] = [
    { title: "CLIK Result", onClick: () => setSelectedClikResultId(null) },
    {
      title: "CLIK Result Details",
    },
  ];

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleCloseEditModal = () => {
    setClikResultToEdit(null);
    clikDataEditModalClose();
  };

  const handleEditClikData = useCallback(
    (value: UpdateClikResultInput) => {
      if (!clikResultToEdit) {
        return;
      }
      updateClikResult({ id: clikResultToEdit.id, input: value }).then(
        refreshData
      );
    },
    [clikResultToEdit, refreshData, updateClikResult]
  );

  const handleError = () => {
    setSelectedClikResultId(null);
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
  };

  return (
    <>
      {selectedClikResultId ? (
        <Flex gap={{ base: 20, sm: 16 }} direction="column">
          <Breadcrumbs>
            {breadcrumbs.map((item, index) => (
              <Text key={index} onClick={item.onClick}>
                {item.title}
              </Text>
            ))}
          </Breadcrumbs>
          <ClikResultDetails
            clikResultId={selectedClikResultId}
            onError={handleError}
          />
        </Flex>
      ) : (
        <>
          <Table
            columns={columns}
            rows={rows}
            defaultSort={{
              columnAccessor: "createdAt",
              direction: SortDirection.asc,
            }}
            loading={fetching}
            pagination={{
              pageCount: pageCount,
              page: page,
              onPageChange: handlePageChange,
            }}
            onSortChange={handleOnSortChange}
          />

          {clikResultToEdit?.data && (
            <EditClikResultModal
              opened={clikDataEditModalOpened}
              value={clikResultToEdit?.data}
              onClose={handleCloseEditModal}
              onSubmit={handleEditClikData}
            />
          )}
        </>
      )}
    </>
  );
};

export default ClikResultList;
