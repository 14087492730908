import { Button, Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import SvgPlus from "../../components/Icons/Plus.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Table from "../../components/Table/Table.tsx";
import { SortDirection } from "../../components/Table/types.ts";
import Title from "../../components/Title/Title.tsx";
import {
  LimitRequestOrderBy,
  OrderBy,
  RolePermission,
  UpdateLimitRequestResultInput,
} from "../../graphql/generated.ts";
import { useLimitRequests } from "../../hooks/api/limitRequest/useLimitRequests.ts";
import { useUpdateLimitRequestResult } from "../../hooks/api/limitRequest/useUpdateLimitRequestResult.ts";
import { useCollectors } from "../../hooks/api/user/useCollectors.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { getPagesCount } from "../../utils/pagination.ts";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404.tsx";
import { useLimitRequestTableData } from "./hooks/useLimitRequestTableData.tsx";
import { getLimitRequestApiSort } from "./utils.ts";

const LimitRequestPage = () => {
  const { user: currentUser } = useCurrentUserContext();

  const [orderBy, setOrderBy] = useState<LimitRequestOrderBy>({
    createdAt: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);

  const [{ data, fetching }, refreshlimitRequest] = useLimitRequests({
    orderBy,
    page,
  });

  const [{ data: collectorsData }] = useCollectors();

  const [, updateLimitRequest] = useMutationNotificationWrapper(
    useUpdateLimitRequestResult(),
    {
      success: { message: "Limit Request has been updated." },
    }
  );

  const navigate = useNavigate();

  const hasViewAccess = hasPermission(
    currentUser,
    RolePermission.CanViewLimitRequest
  );
  const hasAddAccess = hasPermission(
    currentUser,
    RolePermission.CanAddLimitRequest
  );

  const limitRequests = data?.limitRequests?.data;

  const pageCount = getPagesCount(data?.limitRequests?.total);

  const handleUpdateLimitRequest = useCallback(
    (id: number, input: UpdateLimitRequestResultInput) => {
      updateLimitRequest({ id, input }).then((data) => {
        if (!data.error) {
          refreshlimitRequest();
        }
      });
    },
    [refreshlimitRequest, updateLimitRequest]
  );

  const [columns, rows] = useLimitRequestTableData({
    data: limitRequests,
    collectors: collectorsData?.users.data,
    onChange: handleUpdateLimitRequest,
  });

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getLimitRequestApiSort(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleAddLimitRequest = () => navigate("/limit-request/create");

  if (!hasViewAccess) {
    return <Page404 />;
  }

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Flex direction="column" gap={16}>
          <Title size="h1">Limit Request</Title>
          {hasAddAccess && (
            <Button
              uppercase
              variant="filledGolden"
              leftIcon={<SvgPlus />}
              onClick={handleAddLimitRequest}
            >
              Add
            </Button>
          )}
        </Flex>
        <Table
          loading={fetching}
          columns={columns}
          rows={rows}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.asc,
          }}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          onSortChange={handleSortChange}
        />
      </Flex>
    </Layout>
  );
};

export default LimitRequestPage;
