import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikContractCommonData } from "../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { useStyles } from "../../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikContractCommonData | null | undefined;
  information?: string | null;
}

interface CRContractDetailInfo {
  main: MultiFieldCardItem[];
}

export const useCRContractDetailsCardsInfo = ({
  data,
  information,
}: Props): CRContractDetailInfo => {
  const { classes } = useStyles();

  const main = useMemo(() => {
    return [
      {
        label: "Provider Type",
        content: data?.providerType && (
          <Text className={classes.infoText}>{data.providerType}</Text>
        ),
      },
      {
        label: "Provider",
        content: data?.providerCode && (
          <Text className={classes.infoText}>{data.providerCode}</Text>
        ),
      },
      {
        label: "CB Contract Code",
        content: data?.cbContractCode && (
          <Text className={classes.infoText}>{data.cbContractCode}</Text>
        ),
      },
      {
        label: "Provider Contract No.",
        content: data?.providerContractNumber && (
          <Text className={classes.infoText}>
            {data.providerContractNumber}
          </Text>
        ),
      },
      {
        label: "Role",
        content: data?.role && (
          <Text className={classes.infoText}>{data.role}</Text>
        ),
      },
      {
        label: "Last Update Date",
        content: data?.referenceDate && (
          <Text className={classes.infoText}>
            {toLocalDate(data?.referenceDate)}
          </Text>
        ),
      },
      {
        label: "Start Date",
        content: data?.startDate && (
          <Text className={classes.infoText}>
            {toLocalDate(data.startDate)}
          </Text>
        ),
      },
      {
        label: "Due Date",
        content: data?.dueDate && (
          <Text className={classes.infoText}>{toLocalDate(data.dueDate)}</Text>
        ),
      },
      {
        label: "Past Due Status",
        content: data?.pastDueStaus && (
          <Text className={classes.infoText}>{data.pastDueStaus}</Text>
        ),
      },
      {
        label: "Contract Status",
        content: data?.contractStatus && (
          <Text className={classes.infoText}>{data.contractStatus}</Text>
        ),
      },
      {
        label: "Contract Status Date",
        content: data?.contractStatusDate && (
          <Text className={classes.infoText}>
            {toLocalDate(data.contractStatusDate)}
          </Text>
        ),
      },
      {
        label: "Information",
        content: information && (
          <Text className={classes.infoText}>{information}</Text>
        ),
      },
    ];
  }, [data, information, classes]);

  return { main };
};
