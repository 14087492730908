import { Text } from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { RawLimitRequest } from "../../../../types/limitRequest.ts";
import { toPrice } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { useStyles } from "../CompanyInfo.styles.ts";

interface Props {
  limitRequest: RawLimitRequest | null | undefined;
}

export const useLimitRequestCompanyInfoCards = ({ limitRequest }: Props) => {
  const { classes } = useStyles();

  const companyInfo = useMemo(() => {
    return [
      {
        label: "Company Clik Result Name",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.company.name}
          </Text>
        ),
      },
      {
        label: "Sales Name",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.company.salesName}
          </Text>
        ),
      },
      {
        label: "Sales Team",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.company.salesTeamName}
          </Text>
        ),
      },
      {
        label: "Business Identity Number",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.bussinessIdentifyNumber}
          </Text>
        ),
      },
      {
        label: "Phone Number",
        content: (
          <Link
            to={limitRequest?.phone ?? ""}
            target="_blank"
            className={classes.companyInfoText}
          >
            {limitRequest?.phone}
          </Link>
        ),
      },
      {
        label: "NPWP company",
        content: limitRequest?.npwpCompany?.url && (
          <Link
            to={limitRequest.npwpCompany.url}
            target="_blank"
            className={classes.companyInfoText}
          >
            Download
          </Link>
        ),
      },
    ];
  }, [classes, limitRequest]);

  const companyAddress = useMemo(() => {
    return [
      {
        label: "NPWP address",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.npwpAddress}
          </Text>
        ),
      },
      {
        label: "Sub-district",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.subDistrict}
          </Text>
        ),
      },
      {
        label: "District",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.district}
          </Text>
        ),
      },
      {
        label: "City",
        content: (
          <Text className={classes.companyInfoText}>
            {toHumanReadable(limitRequest?.city.name)}
          </Text>
        ),
      },
      {
        label: "Postal Code",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.postalCode}
          </Text>
        ),
      },
      {
        label: "Country",
        content: limitRequest?.country?.name && (
          <Text className={classes.companyInfoText}>
            {toHumanReadable(limitRequest.country.name)}
          </Text>
        ),
      },
    ];
  }, [classes, limitRequest]);

  const limitRequestInfo = useMemo(() => {
    return [
      {
        label: "Limit Request",
        content: (
          <Text className={classes.companyInfoText}>
            {toPrice(limitRequest?.limitRequestAmount)}
          </Text>
        ),
      },
      {
        label: "Tenor Request (days)",
        content: (
          <Text className={classes.companyInfoText}>
            {limitRequest?.tenorRequest}
          </Text>
        ),
      },
      {
        label: "Surat Pengajuan Limit",
        content: limitRequest?.suratPengajuanLimit?.url && (
          <Link
            to={limitRequest.suratPengajuanLimit.url}
            target="_blank"
            className={classes.companyInfoText}
          >
            Download
          </Link>
        ),
      },
      {
        label: "Form LOI",
        content: limitRequest?.formLOI?.url && (
          <Link
            to={limitRequest.formLOI.url}
            target="_blank"
            className={classes.companyInfoText}
          >
            Download
          </Link>
        ),
      },
    ];
  }, [classes, limitRequest]);

  return [companyInfo, companyAddress, limitRequestInfo];
};
