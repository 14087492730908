import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const activeBadge = (theme: MantineTheme) => ({
  root: {
    background: "rgba(72, 150, 35, 0.10)",
    color: theme.colors.greenIce[3],
    fontSize: rem(12),
    fontWeight: 500,
    lineHeight: 1.3,
  },
});

const inactiveBadge = (theme: MantineTheme) => ({
  root: {
    background: "rgba(176, 182, 189, 0.10)",
    color: theme.colors.grayIce[0],
  },
});

export default function badgeTheme(): ThemeComponent {
  return {
    variants: {
      active: activeBadge,
      inactive: inactiveBadge,
    },
    sizes: {
      s: () => ({
        root: {
          height: rem(20),
          padding: `${rem(2)} ${rem(8)}`,
          borderRadius: rem(2),
          textTransform: "capitalize",
        },
      }),
    },
  };
}
