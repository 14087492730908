import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ContractType } from "../../../../../../graphql/generated.ts";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";
import { useStyles } from "../../../ClikResultDetails.styles.tsx";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
  type: ContractType;
}

export const useCROverdueDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  const { classes } = useStyles();

  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: "Principal Overdue Amount",
          content: data?.principalOverdueAmount && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.principalOverdueAmount)}
            </Text>
          ),
        },
        {
          label: "Interest Overdue Amount",
          content: data?.interestOverdueAmount && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.interestOverdueAmount)}
            </Text>
          ),
        },
        {
          label: "Days Past Due",
          content: data?.daysPastDue && (
            <Text className={classes.infoText}>{data.daysPastDue}</Text>
          ),
        },
        {
          label: "Overdue Payments Number",
          content: data?.overduePaymentsNumber && (
            <Text className={classes.infoText}>
              {data.overduePaymentsNumber}
            </Text>
          ),
        },
        {
          label: "Bad Credit Cause",
          content: data?.badCreditCause && (
            <Text className={classes.infoText}>{data.badCreditCause}</Text>
          ),
        },
        {
          label: "Bad Credit Date",
          content: data?.badCreditDate && (
            <Text className={classes.infoText}>{data.badCreditDate}</Text>
          ),
        },
        {
          label: "Penalty",
          content: data?.penalty && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.penalty)}
            </Text>
          ),
        },
      ];
    }

    if (type === ContractType.Bond || type === ContractType.OtherFacilities) {
      return [
        {
          label: "Overdue Amount",
          content: data?.overdueAmount && (
            <Text className={classes.infoText}>
              {toCurrencyPrice(data.overdueAmount)}
            </Text>
          ),
        },
        {
          label: "Days Past Due",
          content: data?.daysPastDue && (
            <Text className={classes.infoText}>{data.daysPastDue}</Text>
          ),
        },
        {
          label: "Bad Credit Cause",
          content: data?.badCreditCause && (
            <Text className={classes.infoText}>{data.badCreditCause}</Text>
          ),
        },
        {
          label: "Bad Credit Date",
          content: data?.badCreditDate && (
            <Text className={classes.infoText}>{data.badCreditDate}</Text>
          ),
        },
      ];
    }

    return [];
  }, [data, classes, type]);
};
