import { UserRole, UserStatus } from "../../graphql/generated.ts";
import { toHumanReadable } from "../../utils/string.ts";

export const userRoles = Object.values(UserRole).map((role) => ({
  value: role,
  label: toHumanReadable(role),
}));

export const userStatuses = Object.values(UserStatus).map((role) => ({
  value: role,
  label: toHumanReadable(role),
}));
