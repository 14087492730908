import { Select } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import { getCollectorOptions } from "../../../components/Invoice/InvoicesTable/utils.ts";
import { TableData, TableRow } from "../../../components/Table/types.ts";
import TableFilesDisplay from "../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import {
  LimitClikResult,
  RolePermission,
  UpdateLimitRequestResultInput,
} from "../../../graphql/generated.ts";
import { LimitRequest } from "../../../types/limitRequest.ts";
import { Collector, User } from "../../../types/user.ts";
import { toLocalDateTime } from "../../../utils/date.ts";
import { toLocalNumber } from "../../../utils/number.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import { getUserDisplayName, hasPermission } from "../../../utils/user.ts";
import { limitClikResultOptions } from "./constants.ts";

interface Props {
  data: LimitRequest[] | null | undefined;
  collectors?: Collector[] | null | undefined;
  onChange: (id: number, value: UpdateLimitRequestResultInput) => void;
}

export function useLimitRequestTableData({
  data,
  collectors,
  onChange,
}: Props): TableData {
  const { user: currentUser } = useCurrentUserContext();

  const hasDownloadFilesAccess = hasPermission(
    currentUser,
    RolePermission.CanDownloadLimitRequestDocuments
  );
  const hasEditClikResultAccess = hasPermission(
    currentUser,
    RolePermission.CanEditLimitRequestClikResult
  );
  const hasAssignCUAccess = hasPermission(
    currentUser,
    RolePermission.CanAssignLimitRequestCu
  );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: "Request Timestamp",
      sortable: true,
      width: "230px",
    },
    {
      accessor: "companyName",
      title: "Company Name",
      sortable: true,
      width: "230px",
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "salesTeam",
      title: "Sales Team",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "limitRequestAmount",
      title: "Limit Request",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "tenorRequest",
      title: "Tenor Request",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "limitRequestType",
      title: "Limit Request Type",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "suratPengajuanLimit",
      title: "Surat Pengajuan Limit",
      sortable: false,
      width: "150px",
    },
    {
      accessor: "loiForm",
      title: "LOI Form",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "clikDate",
      title: "CLIK date",
      sortable: false,
      width: "150px",
    },
    {
      accessor: "clikResult",
      title: "CLIK Result",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "cuAssignedName",
      title: "CU Assigned",
      sortable: true,
      width: "200px",
    },
  ];

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    //  stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();
  };

  const handleOnChange = useCallback(
    (id: number, value: UpdateLimitRequestResultInput) => {
      onChange(id, value);
    },
    [onChange]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        createdAt: toLocalDateTime(item.createdAt),
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeam: item.company.salesTeamName,
        limitRequestAmount: toLocalNumber(item.limitRequestAmount),
        tenorRequest: toLocalNumber(item.tenorRequest),
        suratPengajuanLimit: item.suratPengajuanLimit ? (
          <TableFilesDisplay
            disabled={!hasDownloadFilesAccess}
            files={[item.suratPengajuanLimit]}
          />
        ) : null,
        loiForm: item.formLOI ? (
          <TableFilesDisplay
            disabled={!hasDownloadFilesAccess}
            files={[item.formLOI]}
          />
        ) : null,
        clikDate: toLocalDateTime(item.clikDate),
        limitRequestType:
          item.limitRequestType && toHumanReadable(item.limitRequestType),
        clikResult: hasEditClikResultAccess ? (
          <Select
            key={item.id}
            data={limitClikResultOptions}
            value={item.clikResult}
            placeholder="Select a Request Status"
            size="s"
            withinPortal
            zIndex={100}
            onClick={handleOnClick}
            onChange={(value: LimitClikResult) =>
              handleOnChange(item.id, { clikResult: value })
            }
          />
        ) : (
          item.clikResult
        ),
        cuAssignedName: hasAssignCUAccess ? (
          <Select
            key={item.id}
            data={getCollectorOptions(collectors)}
            value={item.cuAssigned ? String(item.cuAssigned.id) : null}
            placeholder="Select a CU Assigned"
            size="s"
            withinPortal
            zIndex={100}
            onClick={handleOnClick}
            onChange={(value) =>
              handleOnChange(item.id, { cuAssignedId: Number(value) })
            }
          />
        ) : (
          getUserDisplayName(item.cuAssigned as User)
        ),
      };
    });
  }, [
    data,
    hasDownloadFilesAccess,
    hasEditClikResultAccess,
    hasAssignCUAccess,
    collectors,
    handleOnChange,
  ]);

  return [columns, rows];
}
