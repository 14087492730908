import { AppShell } from "@mantine/core";
import { ReactNode } from "react";

import { useCurrentUserContext } from "../Auth/CurrentUserProvider.tsx";
import Header from "./Header/Header.tsx";
import Sidebar from "./Sidebar/Sidebar.tsx";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { user } = useCurrentUserContext();

  if (!user) {
    return;
  }

  return (
    <AppShell
      navbar={<Sidebar />}
      header={<Header user={user} />}
      navbarOffsetBreakpoint="sm"
      padding={24}
      mb={40}
    >
      {children}
    </AppShell>
  );
};

export default Layout;
